import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './style.module.css';

const Fruits = ({ fruit, productImgs, index }) => {
	const { state } = useLocation();
	useEffect(() => {
		if (state === 'plum') {
			window.scrollTo(0, 3400);
		}
		switch (state) {
			case 'plum':
				window.scrollTo(0, 3400);
				break;
			case 'peach':
				window.scrollTo(0, 1250);
				break;
			case 'flat_featch':
				window.scrollTo(0, 2000);
				break;
			case 'apricot':
				window.scrollTo(0, 2750);
				break;
			case 'Nectar':
				window.scrollTo(0, 4300);
				break;
			default:
				window.scrollTo(0, 0);
				break;
		}
	}, []);
	return (
		<div className={styles.content}>
			<div className={styles.contentAboutInfo}>
				<div className={styles.contentLeft}>
					<img src={productImgs?.[index]} alt='fruit' />
				</div>
				<div className={styles.contentRight}>
					<div className={styles.infoBox}>
						<div className={styles.rightTop}>
							<div className={styles.fruitAbout}>
								<h1 className={styles.fruit} dangerouslySetInnerHTML={{ __html: fruit.title.rendered }} />
								<div className={styles.orchardSize}>
									{/* <span>{fruit.hectare}</span>
                                    <span>{fruit.three}</span> */}
								</div>
							</div>
							<div className={styles.imgbox}>
								<img src={fruit.acf.product_icon.url} alt='icon' />
							</div>
						</div>
						<div dangerouslySetInnerHTML={{ __html: fruit.content.rendered }} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Fruits;
