import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { menu, options } from "services/contactServices";

import { DEFAULT_LANG } from "../constants/constants";
import i18n from "i18next";

export const AppContext = React.createContext({
  language: DEFAULT_LANG,
  optionInfo: [],
  menuInfo: [],
  loading: false,
  onChangeLanguage: () => null,
  optionFetch: () => null,
  menuFetch: () => null,
});

export const AppContextProvider = ({ children }) => {
  const { pathname } = useLocation();
  const [lang, setLang] = useState(localStorage.getItem("@lng") || "en");
  const [optionInfo, setOptionInfo] = useState([]);
  const [menuInfo, setMenuInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      setLoading(true);
      const optionsPromise = lang !== 'en' ? options(`?lang=${lang}`) : options();
      const menuPromise = lang !== 'en' ? menu(`?lang=${lang}`) : menu();

      const [optionsRes, menuRes] = await Promise.all([optionsPromise, menuPromise]);
   

      setOptionInfo(optionsRes?.data);
      setMenuInfo(menuRes?.data);
    } catch (err) {
      
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, [1000]);
    }
  };

  useEffect(() => {
    fetchData();
    handleNavigation(pathname, lang);
  }, [lang, pathname]);

  const handleNavigation = (pathname, lang) => {
    if (shouldRedirectToLanguageRoot(pathname, lang)) {
      redirectToLanguageRoot(lang);
    } else if (shouldAppendLanguageToRoot(pathname, lang)) {
      appendLanguageToRoot(pathname, lang);
    }
  };

  const shouldRedirectToLanguageRoot = (pathname, lang) => {
    return ["/hy"].includes(pathname);
  };

  const redirectToLanguageRoot = (lang) => {
    navigate("/" + lang);
  };

  const shouldAppendLanguageToRoot = (pathname, lang) => {
    return (
      ["hy", "ru"].includes(lang) &&
      ["/", "/about-us", "/packaging", "/refrigerators", "/products", "/contact-us"].includes(pathname)
    );
  };

  const appendLanguageToRoot = (pathname, lang) => {
    navigate(pathname + `/${lang}`);
  };

  const onChangeLanguage = useCallback(
    (lng) => {
      i18n.changeLanguage(lng);
      setLang(lng);
      localStorage.setItem("@lng", lng);
      let newPathname;

      if (lng === "en") {
        newPathname = pathname.replace(`/${lang}`, "");
      } else {
        if (pathname.includes(`/${lang}`)) {
          newPathname = pathname.replace(`/${lang}`, `/${lng}`);
        } else {
          newPathname = pathname === "/" ? `/${lng}` : `${lng}${pathname}`;
        }
      }

      // Navigate to the new URL pathname
      navigate(newPathname);
    },
    [pathname, lang]
  );

  const ref = useRef(0);
  ref.current++;


  const values = useMemo(
    () => ({
      language: lang,
      onChangeLanguage,
      optionInfo,
      menuInfo,
      // loading,
    }),
    [lang, onChangeLanguage, optionInfo, menuInfo]
  );
  return (
    <AppContext.Provider key={lang} value={values}>
      {children}
    </AppContext.Provider>
  );
};
