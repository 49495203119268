import React, { useEffect, useState } from "react";
import { blogImgs, blogInfo } from "services/contactServices";
import styles from "./style.module.css";

import BlogItemBox from "./BlogItemBox/BlogItemBox";
import StayInKnowBox from "./StayInKnowBox/StayInKnowBox";
import { useApp } from "hook/useApp";

const StayInKnow = () => {
  const [blog, setBlog] = useState([]);
  const [blogImg, setBlogImg] = useState([]);
  const { language } = useApp();

  const blogFetch = async () => {
    try {
      const res = await blogInfo(`?lang=${language}`);
      setBlog(res?.data);
      for (const element of res?.data?.slice(-2)) {
        const result = element?._links?.["wp:featuredmedia"]?.[0]?.href;
        const result2 = element?._links?.["wp:attachment"]?.[0]?.href;
        const response = await blogImgs(result || result2);
        setBlogImg((prev) => [...prev, response?.data?.guid?.rendered]);
      }
    } catch {
      
    }
  };

  useEffect(() => {
    blogFetch();
  }, []);

  return (
    <section className={styles.stayInKnowSection}>
      <div className={styles.stayInKnowContent}>
        <StayInKnowBox />
        {blog?.slice(-2).map((blogItem, index) => (
          <BlogItemBox
            key={blogItem.id}
            blogImg={blogImg?.filter(Boolean)}
            blogItem={blogItem}
            blog={blog}
            index={index}
          />
        ))}
      </div>
    </section>
  );
};

export default StayInKnow;
