import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import styles from "./style.module.css";

import {
  email,
  emailMessage,
  name,
  nameMessage,
  phoneMessage,
  termMessage,
} from "../../constants/validation";
import { useApp } from "../../hook/useApp";
import { t } from "../../i18n";
import { Contact } from "../../services/contactServices";
import Spinner from "../spinner";

const ReadyToTake = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorData, setErrorData] = useState(false);
  const [phoneValue, setPhoneValue] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const res = await Contact(data);
      setErrorData(res?.data?.errors);
    } catch {
      
    } finally {
      setIsLoading(false);
    }
  };
  const onChangeValue = (e) => {
    const numericValue = e.target.value.replace(/\D/g, "");
    setPhoneValue(numericValue);
  };
  const {
    optionInfo: { acf },
  } = useApp();
  return (
    <section className={styles.readyToTakeSection}>
      <div className={styles.readyToTakeContent}>
        <div className={styles.readyToTakeContentLeft}>
          <h1 className={styles.title}>{acf?.form_title}</h1>
          <h1 className={styles.mobiletitle}>
            {t("business_with_TradeBerry")}
          </h1>
          <div className={styles.contactUs}>
            <Link to="/contact-us" className={styles.contactUsBtn}>
              {t("contact_us")}
              <span className={styles.arrowspan}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="13"
                  viewBox="0 0 16 13"
                  fill="none"
                >
                  <path
                    d="M15.5303 7.03033C15.8232 6.73744 15.8232 6.26256 15.5303 5.96967L10.7574 1.1967C10.4645 0.903807 9.98959 0.903807 9.6967 1.1967C9.40381 1.48959 9.40381 1.96447 9.6967 2.25736L13.9393 6.5L9.6967 10.7426C9.40381 11.0355 9.40381 11.5104 9.6967 11.8033C9.98959 12.0962 10.4645 12.0962 10.7574 11.8033L15.5303 7.03033ZM-6.55671e-08 7.25L15 7.25L15 5.75L6.55671e-08 5.75L-6.55671e-08 7.25Z"
                    fill="white"
                  />
                </svg>
              </span>
            </Link>
          </div>
        </div>
        <div className={styles.readyToTakeContentRight}>
          <p className={styles.readyToTakeContentRightTitle}></p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.formBox}>
              <div className={styles.inputBox}>
                <label htmlFor="name">{t("Your_name")}</label>
                <input
                  {...register("name", {
                    required: true,
                    pattern: {
                      value: name,
                    },
                  })}
                  maxLength={50}
                  type="text"
                  id="name"
                  placeholder={t("Your_first_name")}
                />
                {errorData?.name ? (
                  <p>{errorData?.name[0]}</p>
                ) : errors?.name ? (
                  <p>{nameMessage}</p>
                ) : (
                  ""
                )}
              </div>
              <div className={styles.inputBox}>
                <label htmlFor="address">{t("Your_email_address")}</label>
                <input
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: email,
                    },
                  })}
                  maxLength={150}
                  type="email"
                  id="address"
                  placeholder={t("Your_email_address")}
                />
                {errorData?.email ? (
                  <p>{errorData?.email[0]}</p>
                ) : errors?.email ? (
                  <p>{emailMessage}</p>
                ) : (
                  ""
                )}
              </div>
              <div className={styles.inputBox}>
                <label htmlFor="phoneNumber">{t("Your_phone_number")}</label>
                <input
                  {...register("phone", { required: true })}
                  type="phone"
                  id="phoneNumber"
                  placeholder={t("Your_phone_number")}
                  maxLength={12}
                  onChange={onChangeValue}
                  value={phoneValue}
                />
                {errorData?.phone ? (
                  <p>{errorData?.phone[0]}</p>
                ) : errors?.phone ? (
                  <p>{phoneMessage}</p>
                ) : (
                  ""
                )}
              </div>
              <div className={styles.inputBox}>
                <label htmlFor="comment">{t("Your_message")}</label>
                <textarea
                  {...register("text")}
                  id="comment"
                  name="comment"
                  maxLength={300}
                  placeholder={t("hi")}
                ></textarea>
              </div>
              <div className={styles.inputBoxCheckbox}>
                <input
                  {...register("terms", {
                    required: true,
                  })}
                  type="checkbox"
                  id="PrivacyPolicy"
                  name="terms"
                />
                <label htmlFor="PrivacyPolicy">{t("by_subscription")}</label>
              </div>
              <div className={styles.terms}>
                {errorData?.terms ? (
                  <p className="error">{errorData?.terms[0]}</p>
                ) : errors?.terms ? (
                  <p className="error">{termMessage}</p>
                ) : (
                  ""
                )}
              </div>
              <div>
                <button className={styles.formButton}>
                  {!isLoading ? (
                    <>
                      {t("send_now")}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="13"
                        viewBox="0 0 16 13"
                        fill="none"
                      >
                        <path
                          d="M15.5303 7.03033C15.8232 6.73744 15.8232 6.26256 15.5303 5.96967L10.7574 1.1967C10.4645 0.903807 9.98959 0.903807 9.6967 1.1967C9.40381 1.48959 9.40381 1.96447 9.6967 2.25736L13.9393 6.5L9.6967 10.7426C9.40381 11.0355 9.40381 11.5104 9.6967 11.8033C9.98959 12.0962 10.4645 12.0962 10.7574 11.8033L15.5303 7.03033ZM-6.55671e-08 7.25L15 7.25L15 5.75L6.55671e-08 5.75L-6.55671e-08 7.25Z"
                          fill="white"
                        />
                      </svg>
                    </>
                  ) : (
                    <Spinner color="#008210" />
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ReadyToTake;
