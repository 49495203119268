import ApricotImage from './components/FruitsImages/Apricot';
import FlatPeachImage from './components/FruitsImages/FlatPeach';
import NectarImage from './components/FruitsImages/Nectar';
import PeachImage from './components/FruitsImages/Peach';
import PlumImage from './components/FruitsImages/Plum';

export const FRUITS = [
	{
		id: 1,
		name: 'apricot',
		variety: 'cebas_red_apricot',
		hectare: 4.9,
		three: 1040,
		image: <ApricotImage />,
		color: '--apricot',
	},
	{
		id: 2,
		name: 'Nectar',
		variety: 'Boreal',
		hectare: 4.9,
		three: 1040,
		image: <NectarImage />,
		color: '--nectar',
	},
	{
		id: 3,
		name: 'flat_featch',
		variety: 'samantha',
		hectare: 4.9,
		three: 1040,
		image: <FlatPeachImage />,
		color: '--flat-peach',
	},
	{
		id: 4,
		name: 'flat_featch',
		variety: 'UFO 4',
		hectare: 4.9,
		three: 1040,
		image: <FlatPeachImage />,
		color: '--flat-peach',
	},
	{
		id: 5,
		name: 'flat_featch',
		variety: 'flat_peach_babylon',
		hectare: 4.9,
		three: 1040,
		image: <FlatPeachImage />,
		color: '--flat-peach',
	},
	{
		id: 6,
		name: 'plum',
		variety: 'plum_black_diamond',
		hectare: 4.9,
		three: 1040,
		image: <PlumImage />,
		color: '--plum',
	},
	{
		id: 7,
		name: 'apricot',
		variety: 'nelson',
		hectare: 4.9,
		three: 1040,
		image: <ApricotImage />,
		color: '--apricot',
	},
	{
		id: 8,
		name: 'peach',
		variety: 'elegant_lady',
		hectare: 4.9,
		three: 1040,
		image: <PeachImage />,
		color: '--peach',
	},
	{
		id: 9,
		name: 'peach',
		variety: 'cresthaven_peach',
		hectare: 4.9,
		three: 1040,
		image: <PeachImage />,
		color: '--peach',
	},
	{
		id: 10,
		name: 'nectarin',
		variety: 'amiga',
		hectare: 4.9,
		three: 1040,
		image: <NectarImage />,
		color: '--nectar',
	},
	{
		id: 11,
		name: 'peach',
		variety: 'fresh_late_peach',
		hectare: 4.9,
		three: 1040,
		image: <PeachImage />,
		color: '--peach',
	},
	{
		id: 12,
		name: 'nectarin',
		variety: 'nectarine_venus',
		hectare: 4.9,
		three: 1040,
		image: <NectarImage />,
		color: '--nectar',
	},
	{
		id: 13,
		name: 'plum',
		variety: 'plum_angeleno',
		hectare: 4.9,
		three: 1040,
		image: <PlumImage />,
		color: '--plum',
	},
];

export const FRUITS_SVG_DATA = {
	width: 512,
	height: 180,
	elements: [
		{
			type: 'path',
			d: 'M360.591 135.638L361.332 107.317C361.337 107.134 361.486 106.988 361.669 106.988H363.475C363.565 106.988 363.652 107.025 363.716 107.089L375.995 119.618C376.057 119.681 376.091 119.766 376.091 119.854V124.013C376.091 124.07 376.077 124.125 376.05 124.175L369.697 135.809C369.638 135.917 369.524 135.984 369.401 135.984H360.928C360.738 135.984 360.586 135.828 360.591 135.638Z',
			fruitId: 6,
		},
		{
			type: 'path',
			d: 'M260.1 32.4993L260.471 2.1588C260.473 1.97056 260.629 1.82068 260.818 1.82589L278.687 2.32123C278.79 2.32409 278.887 2.37397 278.948 2.45664L300.952 31.948C300.995 32.0063 301.019 32.077 301.019 32.1497V33.1384C301.019 33.3267 300.865 33.4785 300.676 33.4755L260.432 32.8406C260.246 32.8376 260.098 32.685 260.1 32.4993Z',
			fruitId: 5,
		},
		{
			type: 'path',
			d: 'M293.605 66.3011L294.1 36.0412C294.103 35.8531 294.259 35.7039 294.447 35.7097L303.562 35.9896C303.659 35.9926 303.749 36.0371 303.811 36.1118L328.358 65.7935C328.409 65.8555 328.436 65.9338 328.435 66.0143L328.412 67.3183C328.409 67.506 328.253 67.6549 328.065 67.6494L293.932 66.6436C293.748 66.6382 293.602 66.4855 293.605 66.3011Z',
			fruitId: 13,
		},
		{
			type: 'rect',
			x: '157.912',
			y: '0.131836',
			rx: '0.337161',
			transform: 'rotate(1 157.912 0.131836)',
			fruitId: 8,
		},
		{
			type: 'rect',
			x: '157.492',
			y: '33.5098',
			rx: '0.337161',
			transform: 'rotate(1 157.492 33.5098)',
			fruitId: 8,
		},
		{
			type: 'rect',
			x: '192.479',
			y: '0.550781',
			rx: '0.337161',
			transform: 'rotate(1 192.479 0.550781)',
			fruitId: 3,
		},
		{
			type: 'rect',
			x: '191.639',
			y: '33.9287',
			rx: '0.337161',
			transform: 'rotate(1 191.639 33.9287)',
			fruitId: 3,
		},
		{
			type: 'path',
			d: 'M226.106 1.14178C226.109 0.955222 226.263 0.806355 226.449 0.809612L256.958 1.34214C257.144 1.34538 257.292 1.49833 257.289 1.68413L256.843 32.4949C256.841 32.6819 256.686 32.8309 256.5 32.8271L225.986 32.2086C225.801 32.2049 225.653 32.052 225.656 31.8666L226.106 1.14178Z',
			fruitId: 4,
		},
		{
			type: 'path',
			d: 'M225.689 34.6897C225.693 34.5035 225.846 34.3552 226.032 34.3584L256.54 34.8909C256.727 34.8942 256.875 35.0483 256.871 35.2348L256.262 65.7098C256.258 65.8955 256.105 66.0432 255.919 66.0402L225.489 65.5454C225.303 65.5424 225.154 65.3888 225.158 65.2024L225.689 34.6897Z',
			fruitId: 4,
		},
		{
			type: 'path',
			d: 'M259.835 35.3644C259.838 35.1778 259.991 35.029 260.178 35.0323L290.875 35.5681C291.061 35.5713 291.21 35.725 291.206 35.9113L290.657 66.2154C290.653 66.401 290.501 66.5489 290.315 66.5464L259.716 66.134C259.53 66.1315 259.381 65.9783 259.384 65.7919L259.835 35.3644Z',
			fruitId: 0,
		},
		{
			type: 'rect',
			x: '156.648',
			y: '67.3086',
			rx: '0.337161',
			transform: 'rotate(1 156.648 67.3086)',
			fruitId: 9,
		},
		{
			type: 'rect',
			x: '190.963',
			y: '67.7305',
			rx: '0.337161',
			transform: 'rotate(1 190.963 67.7305)',
			fruitId: 3,
		},
		{
			type: 'rect',
			x: '224.848',
			y: '68.4033',
			rx: '0.337161',
			transform: 'rotate(1 224.848 68.4033)',
			fruitId: 4,
		},
		{
			type: 'rect',
			x: '259.078',
			y: '68.9951',
			rx: '0.337161',
			transform: 'rotate(1 259.078 68.9951)',
			fruitId: 1,
		},
		{
			type: 'rect',
			x: '293.131',
			y: '69.5869',
			rx: '0.337161',
			transform: 'rotate(1 293.131 69.5869)',
			fruitId: 13,
		},
		{
			type: 'path',
			d: 'M327.749 100.523L328.038 70.6767C328.04 70.4917 328.19 70.3428 328.375 70.3428H332.521C332.62 70.3428 332.714 70.3862 332.778 70.4615L357.757 99.8342C357.809 99.8952 357.838 99.9726 357.838 100.053V101.185C357.838 101.375 357.682 101.527 357.493 101.522L328.079 100.863C327.894 100.859 327.748 100.707 327.749 100.523Z',
			fruitId: 6,
		},
		{
			type: 'rect',
			x: '155.469',
			y: '101.025',
			rx: '0.337161',
			transform: 'rotate(1 155.469 101.025)',
			fruitId: 9,
		},
		{
			type: 'rect',
			x: '189.867',
			y: '101.699',
			rx: '0.337161',
			transform: 'rotate(1 189.867 101.699)',
			fruitId: 3,
		},
		{
			type: 'rect',
			x: '223.918',
			y: '102.207',
			rx: '0.337161',
			transform: 'rotate(1 223.918 102.207)',
			fruitId: 4,
		},
		{
			type: 'rect',
			x: '258.658',
			y: '102.627',
			rx: '0.337161',
			transform: 'rotate(1 258.658 102.627)',
			fruitId: 1,
		},
		{
			type: 'rect',
			x: '292.879',
			y: '103.218',
			rx: '0.337161',
			transform: 'rotate(1 292.879 103.218)',
			fruitId: 13,
		},
		{
			type: 'rect',
			x: '326.867',
			y: '104.059',
			rx: '0.337161',
			transform: 'rotate(1 326.867 104.059)',
			fruitId: 6,
		},
		{
			type: 'path',
			d: 'M288.97 269.986L289.465 239.682C289.468 239.497 289.621 239.348 289.806 239.35L327.2 239.762C327.449 239.765 327.609 240.027 327.497 240.25L326.068 243.108C326.058 243.129 326.05 243.151 326.044 243.174L318.871 270.531C318.831 270.681 318.695 270.785 318.539 270.783L289.302 270.329C289.116 270.326 288.967 270.172 288.97 269.986Z',
			fruitId: 13,
		},
		{
			type: 'rect',
			x: '154.543',
			y: '135.245',
			rx: '0.337161',
			transform: 'rotate(1 154.543 135.245)',
			fruitId: 11,
		},
		{
			type: 'rect',
			x: '154.033',
			y: '169.216',
			rx: '0.337161',
			transform: 'rotate(1 154.033 169.216)',
			fruitId: 11,
		},
		{
			type: 'rect',
			x: '188.436',
			y: '169.892',
			rx: '0.337161',
			transform: 'rotate(1 188.436 169.892)',
			fruitId: 3,
		},
		{
			type: 'rect',
			x: '222.32',
			y: '170.313',
			rx: '0.337161',
			transform: 'rotate(1 222.32 170.313)',
			fruitId: 4,
		},
		{
			type: 'rect',
			x: '257.055',
			y: '171.07',
			rx: '0.337161',
			transform: 'rotate(1 257.055 171.07)',
			fruitId: 7,
		},
		{
			type: 'rect',
			x: '256.551',
			y: '204.869',
			rx: '0.337161',
			transform: 'rotate(1 256.551 204.869)',
			fruitId: 7,
		},
		{
			type: 'path',
			d: 'M255.87 239.542C255.875 239.36 256.024 239.214 256.207 239.214L286.461 239.214C286.649 239.214 286.801 239.369 286.798 239.557L286.266 269.997C286.263 270.183 286.11 270.332 285.923 270.328L255.418 269.796C255.231 269.793 255.082 269.637 255.087 269.45L255.87 239.542Z',
			fruitId: 7,
		},
		{
			type: 'path',
			d: 'M290.76 206.043C290.767 205.86 290.919 205.716 291.103 205.719L321.624 206.252C321.808 206.255 321.955 206.405 321.955 206.589L321.955 236.496C321.955 236.682 321.804 236.833 321.618 236.833L289.947 236.833C289.756 236.833 289.603 236.674 289.61 236.483L290.76 206.043Z',
			fruitId: 13,
		},
		{
			type: 'rect',
			x: '222.152',
			y: '204.194',
			rx: '0.337161',
			transform: 'rotate(1 222.152 204.194)',
			fruitId: 5,
		},
		{
			type: 'rect',
			x: '187.93',
			y: '203.689',
			rx: '0.337161',
			transform: 'rotate(1 187.93 203.689)',
			fruitId: 5,
		},
		{
			type: 'rect',
			x: '291.277',
			y: '171.915',
			rx: '0.337161',
			transform: 'rotate(1 291.277 171.915)',
			fruitId: 13,
		},
		{
			type: 'rect',
			x: '120.15',
			y: '168.625',
			rx: '0.337161',
			transform: 'rotate(1 120.15 168.625)',
			fruitId: 2,
		},
		{
			type: 'rect',
			x: '85.8086',
			y: '168.038',
			rx: '0.337161',
			transform: 'rotate(1 85.8086 168.038)',
			fruitId: 10,
		},
		{
			type: 'rect',
			x: '188.855',
			y: '136.007',
			rx: '0.337161',
			transform: 'rotate(1 188.855 136.007)',
			fruitId: 3,
		},
		{
			type: 'rect',
			x: '223.16',
			y: '136.428',
			rx: '0.337161',
			transform: 'rotate(1 223.16 136.428)',
			fruitId: 4,
		},
		{
			type: 'rect',
			x: '257.898',
			y: '137.185',
			rx: '0.337161',
			transform: 'rotate(1 257.898 137.185)',
			fruitId: 1,
		},
		{
			type: 'rect',
			x: '291.953',
			y: '138.029',
			rx: '0.337161',
			transform: 'rotate(1 291.953 138.029)',
			fruitId: 13,
		},
		{
			type: 'path',
			d: 'M326.151 169.38L326.728 138.876C326.732 138.689 326.887 138.54 327.074 138.545L366.95 139.623C367.133 139.628 367.278 139.777 367.278 139.96V141.163C367.278 141.209 367.269 141.255 367.25 141.298L360.202 157.384C360.148 157.507 360.027 157.586 359.893 157.586H348.65C348.464 157.586 348.313 157.737 348.313 157.923V158.864C348.313 158.91 348.303 158.956 348.285 158.999L343.681 169.522C343.627 169.644 343.506 169.724 343.372 169.724H326.488C326.299 169.724 326.147 169.569 326.151 169.38Z',
			fruitId: 6,
		},
		{
			type: 'path',
			d: 'M34.4391 166.863L82.1414 168.026C82.3268 168.031 82.4737 168.184 82.4703 168.37L81.981 195.53C81.9763 195.787 81.6969 195.944 81.4745 195.815L34.2615 168.385C34.1577 168.325 34.0938 168.214 34.0938 168.093V167.2C34.0938 167.011 34.2498 166.858 34.4391 166.863Z',
			fruitId: 10,
		},
		{
			type: 'path',
			d: 'M183.432 248.711L184.588 204.196C184.593 204.01 184.446 203.855 184.261 203.85L154.001 203.026C153.814 203.021 153.659 203.168 153.654 203.355L152.993 230.637C152.99 230.758 153.052 230.871 153.155 230.934L182.92 248.991C183.142 249.125 183.425 248.97 183.432 248.711Z',
			fruitId: 12,
		},
		{
			type: 'path',
			d: 'M186.798 251.361L187.122 237.829C187.127 237.644 187.281 237.496 187.466 237.5L218.063 238.16C218.25 238.164 218.398 238.319 218.393 238.506L217.657 267.253C217.65 267.503 217.384 267.659 217.163 267.543L186.978 251.667C186.865 251.607 186.795 251.489 186.798 251.361Z',
			fruitId: 5,
		},
		{
			type: 'path',
			d: 'M252.285 238.917L221.438 238.257C221.251 238.253 221.097 238.401 221.093 238.588L220.514 268.714C220.511 268.847 220.587 268.968 220.706 269.025L250.893 283.376C251.113 283.481 251.368 283.325 251.375 283.081L252.614 239.264C252.62 239.077 252.472 238.921 252.285 238.917Z',
			fruitId: 5,
		},
		{
			type: 'path',
			d: 'M285.389 272.886L255.159 272.226C254.975 272.222 254.821 272.368 254.815 272.552L254.411 285.068C254.407 285.2 254.48 285.321 254.597 285.38L285.23 300.717C285.455 300.83 285.718 300.667 285.718 300.416V273.223C285.718 273.04 285.572 272.89 285.389 272.886Z',
			fruitId: 13,
		},
		{
			type: 'path',
			d: 'M318.072 273.724L289.376 273.724C289.147 273.724 288.96 273.907 288.955 274.136L288.338 302.019C288.334 302.188 288.432 302.343 288.587 302.412L304.888 309.73C305.084 309.818 305.315 309.744 305.424 309.559L310.769 300.489C310.786 300.459 310.8 300.427 310.81 300.393L318.476 274.264C318.555 273.994 318.353 273.724 318.072 273.724Z',
			fruitId: 13,
		},
		{
			type: 'path',
			d: 'M342.327 172.168H326.649H326.221C326.037 172.168 325.887 172.315 325.884 172.499L325.307 202.675C325.304 202.863 325.456 203.018 325.644 203.018H340.348C340.476 203.018 340.593 202.946 340.65 202.832L349.624 184.883C349.648 184.836 349.66 184.784 349.66 184.732V182.715C349.66 182.56 349.555 182.425 349.405 182.388L343.631 180.944C343.605 180.938 343.577 180.934 343.55 180.934H341.315C341.129 180.934 340.978 180.783 340.978 180.597V177.057C340.978 176.871 341.129 176.72 341.315 176.72H341.573C341.721 176.72 341.851 176.624 341.895 176.483L342.649 174.071C342.659 174.039 342.664 174.005 342.664 173.971V172.505C342.664 172.319 342.513 172.168 342.327 172.168Z',
			fruitId: 13,
		},
		{
			type: 'path',
			d: 'M149.676 228.908L150.247 203.607C150.251 203.421 150.104 203.267 149.918 203.262L98.2555 202.014C98.0661 202.01 97.9102 202.162 97.9102 202.351V203.063C97.9102 203.19 97.9817 203.306 98.0952 203.364L149.187 229.201C149.408 229.313 149.671 229.156 149.676 228.908Z',
			fruitId: 2,
		},
		{
			type: 'path',
			d: 'M338.622 205.631H325.725H325.388C325.202 205.631 325.051 205.782 325.051 205.968V235.975C325.051 236.162 325.202 236.313 325.388 236.313H330.108C330.294 236.313 330.445 236.162 330.445 235.975V235.132C330.445 234.993 330.559 234.88 330.698 234.88C330.838 234.88 330.951 234.766 330.951 234.627V232.435C330.951 232.296 331.064 232.182 331.204 232.182C331.344 232.182 331.457 232.069 331.457 231.929V231.129C331.457 230.966 331.589 230.834 331.752 230.834C331.915 230.834 332.047 230.702 332.047 230.539V228.389C332.047 228.25 332.16 228.136 332.3 228.136C332.439 228.136 332.553 228.023 332.553 227.883V226.956C332.553 226.817 332.666 226.703 332.805 226.703C332.945 226.703 333.058 226.59 333.058 226.451V220.002C333.058 219.839 333.19 219.707 333.353 219.707C333.516 219.707 333.648 219.575 333.648 219.412V215.661C333.648 215.475 333.799 215.324 333.986 215.324H334.622C334.749 215.324 334.865 215.253 334.922 215.14L338.922 207.305C338.946 207.257 338.959 207.205 338.959 207.151V205.968C338.959 205.782 338.808 205.631 338.622 205.631Z',
			fruitId: 13,
		},
		{
			type: 'path',
			d: 'M271.23 342.202L247.277 329.201C247.073 329.09 246.819 329.165 246.707 329.368L233.472 353.396C233.359 353.601 233.435 353.858 233.641 353.97L243.465 359.282C243.592 359.351 243.745 359.35 243.871 359.279L270.602 344.259C270.677 344.217 270.738 344.153 270.775 344.074L271.409 342.754C271.506 342.552 271.428 342.309 271.23 342.202Z',
			fruitId: 13,
		},
		{
			type: 'path',
			d: 'M299.706 322.614L262.325 302.556C262.121 302.447 261.868 302.522 261.756 302.724L248.519 326.67C248.405 326.875 248.481 327.133 248.686 327.244L273.795 340.867C273.932 340.942 274.1 340.934 274.23 340.848L299.459 324.061C299.532 324.013 299.587 323.943 299.619 323.862L299.9 323.137C299.977 322.939 299.894 322.714 299.706 322.614Z',
			fruitId: 13,
		},
		{
			type: 'path',
			d: 'M258.934 300.675L234.589 287.778C234.384 287.669 234.13 287.747 234.02 287.951L221.272 311.794C221.163 311.997 221.239 312.251 221.442 312.362L245.468 325.516C245.673 325.628 245.93 325.553 246.041 325.347L259.107 301.248C259.219 301.042 259.141 300.785 258.934 300.675Z',
			fruitId: 7,
		},
		{
			type: 'path',
			d: 'M228.363 291.581L204.176 278.595C203.972 278.485 203.717 278.561 203.606 278.765L193.865 296.766C193.754 296.971 193.831 297.226 194.035 297.337L218.308 310.483C218.514 310.595 218.771 310.517 218.881 310.31L228.535 292.15C228.644 291.945 228.567 291.69 228.363 291.581Z',
			fruitId: 7,
		},
		{
			type: 'path',
			d: 'M200.783 276.908L176.596 263.922C176.391 263.812 176.137 263.888 176.026 264.093L166.285 282.093C166.174 282.298 166.25 282.554 166.455 282.664L190.728 295.81C190.934 295.922 191.191 295.844 191.301 295.638L200.955 277.477C201.064 277.272 200.987 277.018 200.783 276.908Z',
			fruitId: 7,
		},
		{
			type: 'path',
			d: 'M173.413 262.107L149.116 248.555C148.912 248.441 148.654 248.515 148.541 248.721L138.791 266.578C138.68 266.782 138.755 267.038 138.959 267.15L163.253 280.436C163.456 280.547 163.711 280.473 163.824 280.271L173.577 262.679C173.689 262.476 173.616 262.22 173.413 262.107Z',
			fruitId: 7,
		},
		{
			type: 'path',
			d: 'M146.219 246.932L121.55 233.607C121.345 233.496 121.089 233.572 120.979 233.777L111.24 251.773C111.129 251.979 111.207 252.237 111.414 252.346L135.908 265.277C136.11 265.384 136.36 265.31 136.472 265.111L146.386 247.509C146.501 247.304 146.426 247.044 146.219 246.932Z',
			fruitId: 5,
		},
		{
			type: 'path',
			d: 'M85.1113 236.717L91.7153 224.335C91.751 224.268 91.8272 224.233 91.9013 224.25L104.129 227.019C104.144 227.023 104.158 227.028 104.172 227.036L116.643 233.84C116.725 233.885 116.755 233.987 116.711 234.068L108.474 249.331C108.43 249.413 108.327 249.444 108.245 249.4L85.18 236.944C85.0983 236.9 85.0677 236.799 85.1113 236.717Z',
			fruitId: 5,
		},
		{
			type: 'path',
			d: 'M88.721 223.216L64.1959 210.074C63.9912 209.965 63.7363 210.041 63.626 210.246L57.5311 221.538C57.4201 221.743 57.4976 222 57.7039 222.11L82.2274 235.17C82.4316 235.279 82.6852 235.203 82.7957 234.999L88.8922 223.789C89.0039 223.583 88.9271 223.326 88.721 223.216Z',
			fruitId: 8,
		},
		{
			type: 'path',
			d: 'M217.187 313.038L192.998 300.05C192.796 299.942 192.545 300.014 192.432 300.213L179.416 323.15C179.301 323.353 179.373 323.611 179.577 323.726L203.87 337.364C204.075 337.479 204.334 337.405 204.446 337.199L217.357 313.611C217.47 313.406 217.393 313.148 217.187 313.038Z',
			fruitId: 7,
		},
		{
			type: 'path',
			d: 'M189.247 297.673L165.058 284.686C164.856 284.578 164.605 284.65 164.492 284.849L151.477 307.786C151.362 307.989 151.434 308.247 151.637 308.361L175.931 322C176.135 322.115 176.394 322.04 176.507 321.835L189.418 298.247C189.53 298.042 189.454 297.784 189.247 297.673Z',
			fruitId: 7,
		},
		{
			type: 'path',
			d: 'M161.821 283.176L137.672 269.667C137.47 269.554 137.214 269.626 137.1 269.827L124.088 292.757C123.973 292.961 124.045 293.219 124.248 293.333L148.545 306.973C148.748 307.087 149.006 307.015 149.119 306.811L161.984 283.75C162.097 283.547 162.024 283.29 161.821 283.176Z',
			fruitId: 7,
		},
		{
			type: 'path',
			d: 'M133.964 268.006L110.331 255.14C110.126 255.029 109.87 255.104 109.759 255.309L97.4491 277.961C97.3393 278.163 97.4117 278.416 97.6117 278.529L120.696 291.603C120.899 291.718 121.156 291.646 121.271 291.443L134.129 268.582C134.245 268.378 134.17 268.118 133.964 268.006Z',
			fruitId: 5,
		},
		{
			type: 'path',
			d: 'M107.214 252.258L83.9539 239.587C83.7493 239.476 83.4932 239.552 83.382 239.756L71.0722 262.408C70.9624 262.61 71.0347 262.863 71.2348 262.976L94.3128 276.047C94.518 276.163 94.7787 276.088 94.891 275.881L107.383 252.829C107.494 252.625 107.418 252.37 107.214 252.258Z',
			fruitId: 5,
		},
		{
			type: 'path',
			d: 'M80.4524 237.716L57.1686 224.849C56.9639 224.736 56.7062 224.811 56.5945 225.016L44.52 247.222C44.4102 247.424 44.4825 247.677 44.6826 247.79L67.7622 260.862C67.9668 260.978 68.2266 260.904 68.3395 260.698L80.6182 238.287C80.7297 238.084 80.6556 237.828 80.4524 237.716Z',
			fruitId: 8,
		},
		{
			type: 'path',
			d: 'M53.3923 222.968L30.5218 210.103C30.3169 209.988 30.0572 210.062 29.9449 210.269L17.8716 232.473C17.7617 232.675 17.8341 232.927 18.0341 233.041L41.1085 246.109C41.3151 246.226 41.5775 246.15 41.6886 245.94L53.5581 223.532C53.6651 223.33 53.5916 223.08 53.3923 222.968Z',
			fruitId: 8,
		},
		{
			type: 'path',
			d: 'M93.0939 277.96L70.2296 265.099C70.0256 264.984 69.7673 265.057 69.6541 265.262L56.785 288.549C56.6733 288.751 56.745 289.006 56.9458 289.12L79.6055 301.981C79.8086 302.096 80.0667 302.024 80.1811 301.82L93.2548 278.533C93.3687 278.331 93.2967 278.074 93.0939 277.96Z',
			fruitId: 5,
		},
		{
			type: 'path',
			d: 'M66.0351 263.385L43.1727 250.524C42.9681 250.409 42.709 250.484 42.5963 250.69L30.1026 273.528C29.9924 273.73 30.0636 273.982 30.2628 274.096L52.758 286.981C52.9611 287.098 53.2201 287.026 53.3348 286.822L66.1958 263.958C66.31 263.756 66.238 263.499 66.0351 263.385Z',
			fruitId: 8,
		},
		{
			type: 'path',
			d: 'M201.548 339.928L177.249 326.286C177.046 326.173 176.79 326.244 176.676 326.446L163.664 349.376C163.549 349.579 163.621 349.837 163.825 349.951L188.124 363.593C188.326 363.706 188.582 363.635 188.697 363.433L201.708 340.503C201.823 340.3 201.752 340.042 201.548 339.928Z',
			fruitId: 7,
		},
		{
			type: 'path',
			d: 'M174.12 324.564L149.821 310.922C149.619 310.809 149.363 310.88 149.248 311.082L136.237 334.011C136.121 334.214 136.193 334.473 136.397 334.587L160.696 348.228C160.898 348.342 161.154 348.271 161.269 348.069L174.28 325.139C174.396 324.936 174.324 324.678 174.12 324.564Z',
			fruitId: 7,
		},
		{
			type: 'path',
			d: 'M147.007 309.202L122.708 295.561C122.505 295.447 122.249 295.518 122.135 295.72L109.123 318.65C109.008 318.853 109.08 319.111 109.284 319.226L133.583 332.867C133.785 332.981 134.041 332.909 134.156 332.708L147.167 309.778C147.282 309.575 147.21 309.317 147.007 309.202Z',
			fruitId: 7,
		},
		{
			type: 'path',
			d: 'M119.509 293.91L96.3529 280.847C96.1508 280.733 95.8946 280.803 95.7797 281.005L82.5031 304.29C82.3861 304.495 82.4606 304.757 82.6681 304.869L106.087 317.583C106.288 317.693 106.541 317.62 106.654 317.421L119.669 294.485C119.784 294.282 119.713 294.024 119.509 293.91Z',
			fruitId: 5,
		},
		{
			type: 'path',
			d: 'M39.9444 248.581L15.6452 234.94C15.4429 234.826 15.1868 234.897 15.0723 235.099L2.06089 258.029C1.94564 258.232 2.0175 258.49 2.22112 258.604L26.5203 272.246C26.7226 272.36 26.9787 272.288 27.0932 272.087L40.1046 249.157C40.2198 248.954 40.148 248.696 39.9444 248.581Z',
			fruitId: 8,
		},
		{
			type: 'path',
			d: 'M243.655 327.203C239.301 325.171 222.857 316.048 220.219 314.582C220.015 314.468 219.77 314.55 219.657 314.754L206.663 338.434C206.551 338.638 206.626 338.895 206.83 339.007L230.77 352.161C230.974 352.273 231.231 352.198 231.343 351.994L244.152 328.597C244.34 328.254 244.009 327.368 243.655 327.203Z',
			fruitId: 7,
		},
		{
			type: 'path',
			d: 'M240.51 360.943L205.415 341.622C205.334 341.578 205.233 341.606 205.187 341.686L191.643 365.293C191.597 365.374 191.624 365.477 191.705 365.523L213.078 377.794C213.13 377.824 213.194 377.823 213.246 377.793L239.826 362.425C239.852 362.411 239.873 362.389 239.888 362.364L240.574 361.174C240.622 361.093 240.592 360.988 240.51 360.943Z',
			fruitId: 13,
		},
	],
};
