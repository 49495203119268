import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { brandfetch, brandsImg } from 'services/contactServices';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './style.module.css';

const Brands = () => {
	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
		// initialSlide: 0,
		prevArrow: null,
		nextArrow: null,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: false,
					prevArrow: null,
					nextArrow: null,
					arrows: false,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: false,
					prevArrow: null,
					nextArrow: null,
					arrows: false,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
					prevArrow: null,
					nextArrow: null,
					arrows: false,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					prevArrow: null,
					nextArrow: null,
					arrows: false,
				},
			},
		],
	};
	const mediaMatch = window.matchMedia('(max-width: 768px)');
	const [matches, setMatches] = useState(mediaMatch.matches);
	const [brandImgs, setBrandImgs] = useState([]);

	const brandsFetch = () => {
		brandfetch()
			.then(res => {
				res?.data.forEach(element => {
					const result = element?._links?.['wp:featuredmedia']?.[0]?.href;
					brandsImg(result).then(response => {
						setBrandImgs(prev => [...prev, response?.data?.guid?.rendered]);
					});
				});
			})
			.catch(err => {
				
			});
	};
	useEffect(() => {
		brandsFetch();
	}, []);
	useEffect(() => {
		const handler = e => setMatches(e.matches);
		mediaMatch.addListener(handler);
		return () => mediaMatch.removeListener(handler);
	});
	return (
		<section className='brandsSection'>
			<div className={styles.brandsSectionContent}>
				<Slider
					{...settings}
					// ref={(c) => (slider.current = c)}
				>
					{brandImgs.map((brand, index) => (
						<div key={index} className={styles.slideItem}>
							<img src={brand} alt='' />
						</div>
					))}
				</Slider>
			</div>
		</section>
	);
};

export default Brands;
