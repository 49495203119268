import React from 'react';
import './spinner.css';

const Spinner = ({ width, height, bradius, bcolor, color, bline, speed }) => {
	return (
		<div
			className='spinner'
			style={{
				width: `${width}`,
				height: `${height}`,
				borderRadius: `${bradius}`,
				borderColor: `${bcolor}`,
				borderWidth: `${bline}`,
				borderTopColor: `${color}`,
				animationDuration: `${speed}`,
			}}
		/>
	);
};

export default Spinner;
