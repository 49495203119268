import React from "react";
import styles from "./style.module.css";
import { t } from "../../i18n";
const Modal = ({ onClose }) => {
  return (
    <div className="modal">
      <div className={styles.modalContent}>
        <div className={styles.closeModal} onClick={onClose}>
          <img src="/close.png" alt="Close" />
        </div>
        <div className={styles.modalContentFlex}>
          <img className={styles.modalImage} src="/objects.png" alt="Success" />
          <div className={styles.modalInfo}>
            <h2 className={styles.modalTitle}>{t("succses_title")}</h2>
            <p className={styles.modalText}>{t("succses_messages")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
