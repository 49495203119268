import React, { useEffect, useState } from "react";
import Loading from "components/Loading";
import { refrigerators } from "services/contactServices";
import styles from "./style.module.css";

import ReadtToTake from "../../components/ReadyToTake/ReadyToTake";
import { useApp } from "hook/useApp";
const Refrigerators = () => {
  const [refrigatorInfo, setRefrigatorInfo] = useState();
  const {language, loading } = useApp();

  const refrigatorsFetch = async () => {
    try {
      const my_lng = language === "en" ? 139 : language === "hy" ? 525 : 526;
      const response = await refrigerators(my_lng);
      // UNDEFINED A GALIS
      const refrigatorsData = response?.data || [];

      setRefrigatorInfo(refrigatorsData);
      // changeTitle(refrigatorsData[0]?.title?.rendered);
    } catch (err) {
     
    }
  };

  useEffect(() => {
    refrigatorsFetch();
  }, []);

  const figureTagRegex = /<figure.*?<\/figure>/;
  const figureTagMatch =
    refrigatorInfo?.content?.rendered?.match(figureTagRegex);
  const modifiedHtmlString = refrigatorInfo?.content?.rendered.replace(
    figureTagRegex,
    ""
  );
  if (loading) {
    // return <Loading />;
  }
  return (
    <>
      <section className={styles.refrigeratorsSection}>
        {refrigatorInfo && (
          <div className={styles.content}>
            <div
              className={styles.contentLeft}
              dangerouslySetInnerHTML={{ __html: modifiedHtmlString }}
            ></div>
            <div
              className={styles.contentRight}
              dangerouslySetInnerHTML={{ __html: figureTagMatch?.[0] }}
            >
              {/* <h5 className={styles.contentLeftTitle}>{t('which_ensures')}</h5> */}
            </div>
          </div>
        )}
        <ReadtToTake />
      </section>
    </>
  );
};

export default Refrigerators;
