import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './style.module.css';

import { t } from '../../../i18n';
import { useApp } from 'hook/useApp';

const BlogItemBox = ({ blogItem, index, blogImg }) => {
	const navigate = useNavigate();
	const inputDateString = blogItem?.modified;
	const inputDate = new Date(inputDateString);
	const options = { year: 'numeric', month: 'long', day: 'numeric' };
	const formattedDate = inputDate.toLocaleDateString('en-US', options);
	const mutationData = new Set(blogImg);
	const imgs = [...mutationData];
	const { language } = useApp();

	return (
		<div className={styles.stayInKnowContentBoxSecond}>
			<div className={styles.stayInKnowTop} onClick={() => navigate(`/blogItem${language === 'en' ? '' : ':lng'}`)}>
				<img src={imgs[index]} alt='BlogImg' />
			</div>
			<div className={styles.stayInKnowbottom}>
				<p
					className={styles.stayInKnowbottomText}
					onClick={() => navigate(`/blogItem${language === 'en' ? '' : ':lng'}`)}
				>
					{blogItem.title.rendered}
				</p>
				<div className={styles.stayInKnowbottomContent}>
					<dov className={styles.readeMoreBox}>
						<p>{formattedDate}</p>
					</dov>
					<div className={styles.readeMoreBox}>
						<Link to={`/blogItem${language === 'en' ? '' : ':lng'}`} className={styles.contactUsBtn}>
							Reade more
							<span className={styles.arrowspan}>
								<svg xmlns='http://www.w3.org/2000/svg' width='16' height='13' viewBox='0 0 16 13' fill='none'>
									<path
										d='M15.5303 7.03033C15.8232 6.73744 15.8232 6.26256 15.5303 5.96967L10.7574 1.1967C10.4645 0.903807 9.98959 0.903807 9.6967 1.1967C9.40381 1.48959 9.40381 1.96447 9.6967 2.25736L13.9393 6.5L9.6967 10.7426C9.40381 11.0355 9.40381 11.5104 9.6967 11.8033C9.98959 12.0962 10.4645 12.0962 10.7574 11.8033L15.5303 7.03033ZM-6.55671e-08 7.25L15 7.25L15 5.75L6.55671e-08 5.75L-6.55671e-08 7.25Z'
										fill='white'
									/>
								</svg>
							</span>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BlogItemBox;
