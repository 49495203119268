const ApricotImage = () => {
	return (
		<svg width={'100%'} height={'100%'} viewBox='0 0 212 226' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				stroke='transparent'
				d='M126.341 61.8143C136.314 72.3643 152.018 86.32 137.769 140.977C158.371 83.2448 134.124 64.4779 118.326 54.6051C118.051 54.433 118.168 54.0159 118.492 53.9937C152.095 51.6856 175.008 83.9423 175.381 104.867C176.245 153.332 147.484 186.522 135.8 196.829C127.183 203.256 119.567 211.34 100.424 211.454C94.3367 213.922 76.4104 214.871 53.4032 198.929C33.5712 184.333 15.3116 147.768 25.155 106.336C30.398 81.1719 47.6085 51.9414 69.8107 45.2504C80.8742 41.0476 105.63 44.4891 126.341 61.8143Z'
				fill='#191D29'
			/>
			<path
				stroke='transparent'
				d='M121.683 15.8944C120.513 17.9888 118.595 23.8848 118.101 33.5829L117.1 43.2486L116.385 40.8697L115.244 46.787C110.583 39.7189 112.817 16.9007 112.455 12.8385C112.335 11.4922 112.985 10.9503 113.63 10.7954C117.654 9.82983 125.522 8.80885 127.778 10.6243C128.242 10.9061 129.142 11.3171 127.706 13.3059C126.651 14.8344 122.854 13.7999 121.683 15.8944Z'
				fill='#191D29'
			/>
			<path
				stroke='transparent'
				d='M118.087 33.1151L117.139 42.5047L116.36 40.2866C115.694 43.9776 114.418 51.3932 114.647 51.5278C141.762 51.2467 161.577 47.3505 181.147 30.2548C183.567 27.8294 192.894 19.3611 193.234 6.18718C154.373 -7.87842 135.63 8.05794 128.835 14.4784C125.943 17.5534 119.743 25.5855 118.087 33.1151Z'
				fill='#191D29'
			/>
			<path
				d='M116.04 68.7361C126.013 79.2862 141.717 93.2418 127.468 147.899C148.07 90.1667 123.824 71.3998 108.025 61.527C107.75 61.3549 107.867 60.9378 108.191 60.9155C141.794 58.6075 164.708 90.8642 165.08 111.789C165.944 160.254 137.183 193.444 125.499 203.751C116.883 210.178 109.266 218.262 90.1232 218.376C84.036 220.843 66.1097 221.793 43.1025 205.851C23.2705 191.254 5.01093 154.69 14.8543 113.258C20.0973 88.0938 37.3078 58.8633 59.51 52.1722C70.5736 47.9695 95.3294 51.4109 116.04 68.7361Z'
				strokeWidth='2.7115'
			/>
			<path
				d='M111.383 22.8182C110.213 24.9127 108.295 30.8086 107.801 40.5067L106.8 50.1724L106.085 47.7935L104.944 53.7108C100.283 46.6428 102.517 23.8245 102.155 19.7624C102.035 18.416 102.685 17.8741 103.33 17.7193C107.354 16.7537 115.222 15.7327 117.478 17.5481C117.942 17.8299 118.842 18.2409 117.406 20.2297C116.351 21.7582 112.554 20.7237 111.383 22.8182Z'
				strokeWidth='2.7115'
			/>
			<path
				d='M107.787 40.0389L106.839 49.4285L106.06 47.2104C105.393 50.9014 104.117 58.317 104.347 58.4517C131.462 58.1705 151.276 54.2743 170.846 37.1787C173.267 34.7532 182.594 26.285 182.933 13.111C144.073 -0.954597 125.33 14.9818 118.535 21.4023C115.642 24.4772 109.443 32.5094 107.787 40.0389Z'
				strokeWidth='2.7115'
			/>
		</svg>
	);
};

export default ApricotImage;
