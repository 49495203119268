import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Loading from "components/Loading";
import { aboutUs } from "services/contactServices";
import styles from "./style.module.css";

import Brands from "../../components/Brands/Brands";
import Certificates from "../../components/Certificates/Certificates";
import ReadyToTake from "../../components/ReadyToTake/ReadyToTake";
import WhyUs from "../../components/whyUsSection/WhyUs";
import { t } from "../../i18n";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useApp } from "hook/useApp";

gsap.registerPlugin(ScrollTrigger);
const AboutUs = () => {
  const firstMaskContentRef = useRef(null);
  const secondMaskContentRef = useRef(null);
  const certificatesRef = useRef(null);
  const readyToTakeRef = useRef(null);
  const whyUsRef = useRef(null);
  const brandsRef = useRef(null);
  const [aboutInfo, setAboutInfo] = useState([]);
  const { language, loading } = useApp();
  const [aboutLoading, setAboutLoading] = useState(false);

  const aboutFetch = async () => {
    setAboutLoading(true);
    try {
      const res = await aboutUs(language === "en" ? 100 : language === "hy" ? 499 : 500);
    
      setAboutInfo(res?.data);
    } catch {
      
    } finally {
      setAboutLoading(false);
    }
  };

  useEffect(() => {
    aboutFetch();
  }, []);

  if (loading) {
    
    // return <Loading />;
  }

  return (
    <section className={styles.aboutUsSection}>
      <div className={styles.aboutContent}>
        {aboutInfo.length !== 0 && (
          <>
            <div
              ref={firstMaskContentRef}
              className={styles.firstMaskContent}
              id="firstMaskContent"
            >
              <div className={styles.title}>
                <h1>{t("continuing_family")}</h1>
              </div>
              <div className={styles.contentLeft}>
                <img src={aboutInfo?.acf?.image_one?.url} alt="test" />
              </div>
              <div className={styles.contentRight}>
                <div className={styles.rightTitle}>
                  <h1>{aboutInfo?.acf?.about_us_content_one_title}</h1>
                </div>
                <div
                  className={styles.contentText}
                  dangerouslySetInnerHTML={{
                    __html: aboutInfo?.acf?.content_description_one,
                  }}
                ></div>
              </div>
            </div>
            <div className={styles.certificatesRef} ref={certificatesRef}>
              <Certificates />
            </div>
            <div
              ref={secondMaskContentRef}
              className={styles.secondMaskContent}
              id="secondMaskContent"
            >
              <div className={styles.title}>
                <h1>{t("оrchard_еxcellence")}</h1>
              </div>
              <div className={styles.contentLeft}>
                <img src={aboutInfo?.acf?.image_two?.url} alt="test" />
              </div>
              <div className={styles.contentRight}>
                <div className={styles.rightTitle}>
                  <h1>{aboutInfo?.acf?.about_us_content_two_title}</h1>
                </div>
                <div
                  className={styles.contentText}
                  dangerouslySetInnerHTML={{
                    __html: aboutInfo?.acf?.content_description_two,
                  }}
                ></div>
              </div>
            </div>
          </>
        )}
        <div className={styles.ReadyToTake} ref={readyToTakeRef}>
          <ReadyToTake />
        </div>
        <div className={styles.whyUs} ref={whyUsRef}>
          <WhyUs />
        </div>
        <div className={styles.brands} ref={brandsRef}>
          <Brands />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
