import { logDOM } from "@testing-library/react";
import AxiosInstance from "../lib/api";
import axios from "axios";
export function Contact(body) {
  const url = "contact-form-7/v1/contact-forms/165/feedback";
  // const formData = {
  //   _wpcf7_unit_tag: "0b3d22f",
  //   "your-message": "messages",
  //   "your-name": "Arman",
  //   "your-email": "098554426@mail.ru",
  //   contact: "098554426",
  //   "your-subject": "esi petqa chishtn lini",
  // };
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Basic YWRtaW46YWRtaW4=",
  };
  const formData = new FormData();
  const data = {
    message: "messages",
    fullname: "test",
    email: "test@mail.ru",
    contact: "test",
    subject: "test",
    _wpcf7_unit_tag: "0b3d22f",
  };
  return AxiosInstance.post(url, formData, { headers });
}
// Product========================================
export function oneProductInfo(params) {
  const url = params ? `wp/v2/product${params}` : 'wp/v2/product';
  return AxiosInstance.get(url);

}
export function oneProductImg(params) {
  return AxiosInstance.get(`${params}`);
}
export function productPageInfo(params) {
  return AxiosInstance.get(`wp/v2/pages/${params}`);
}
// Refrigerators========================================
export function refrigerators(params) {
  return AxiosInstance.get(`wp/v2/pages/${params}`);
}
// About us========================================
export function aboutUs(params) {
  return AxiosInstance.get(`wp/v2/pages/${params}`);
}
// packaging========================================
export function packaging(params) {
  return AxiosInstance.get(`wp/v2/pages/${params}`);
}
// options========================================
export function options(params) {
  const url = params ? `/acf/v2/options${params}` : '/acf/v2/options';
  return AxiosInstance.get(url);
}
// why========================================
export function whyTradeBerry(params) {
  return AxiosInstance.get(`/wp/v2/why${params}`);
}
// faqTradeBerry========================================
export function faqTradeBerry(params) {
  return AxiosInstance.get(`/wp/v2/faq${params}`);
}
// oneProductImg========================================
export function whyIcons(params) {
  return AxiosInstance.get(`${params}`);
}
// brands========================================
export function brandfetch() {
  return AxiosInstance.get(`/wp/v2/partners`);
}
// brandsImg========================================
export function brandsImg(params) {
  return AxiosInstance.get(`${params}`);
}
// menu========================================
export function menu(params) {
  const url = params ? `/custom/v1/menu${params}` : '/custom/v1/menu';
  return AxiosInstance.get(url);
}
// contact========================================
export function contactInfo(params) {
  return AxiosInstance.get(`wp/v2/pages?slug=contact-us&_embed${params}`);
}
// blog========================================
export function blogInfo(params) {
  return AxiosInstance.get(`wp/v2/posts${params}`);
}
// blogImg========================================
export function blogImgs(params) {
  return AxiosInstance.get(`${params}`);
}
