import { initReactI18next } from 'react-i18next';

import { DEFAULT_LANG } from '../src/constants/constants';
import enTranslation from '../src/locales/en/common.json';
import hyTranslation from '../src/locales/hy/common.json';
import ruTranslation from '../src/locales/ru/common.json';
import i18n, { t as _t } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';

export const defaultNS = 'common';

export const resources = {
	en: {
		translation: { ...enTranslation },
	},
	ru: {
		translation: { ...ruTranslation },
	},
	hy: {
		translation: { ...hyTranslation },
	},
};

const urlLanguage = window.location.pathname.includes('/ru') ? 'ru' :
                    window.location.pathname.includes('/hy') ? 'hy' : 'en';


localStorage.setItem("@lng", urlLanguage);

i18n // learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	.use(intervalPlural)
	.use(initReactI18next)
	.init({
		fallbackLng: DEFAULT_LANG,
		// supportedLngs: Object.values(APP_LANGUAGES),
		lng:  localStorage.getItem("@lng") || DEFAULT_LANG,
		debug: false,
		react: {
			useSuspense: false,
		},
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		resources,
	});

export const t = _t;

export default i18n;
