import { Route, Routes, useLocation, Navigate } from "react-router-dom";

import Wrapper from "../components/Wrapper/Wrapper";
import AboutUs from "../pages/AboutUs/AboutUs";
import BlogSingle from "../pages/BlogSingle/BlogSingle";
import Brands from "../pages/Brands/Brands";
import Contacts from "../pages/Contacts/Contacts";
import Ecosystem from "../pages/Ecosystem/Ecosystem";
import Home from "../pages/Home/Home";
import NothFoundPage from "../pages/NothFound";
import Packaging from "../pages/Packaging/Packaging";
import Products from "../pages/Products/Products";
import Refrigerators from "../pages/Refrigerators/Refrigerators";

const AppRouter = () => {
  const location = useLocation();
    if (location.pathname.includes('/en')) {
    return <Navigate to="/" />;
  }
  const getPathWithLanguage = (path) => {
    if (location.pathname.split('/')[1] === 'ru' || location.pathname.split('/')[1] === 'hy' ) {
      return "/:lng" + path;
    }
    return path;
  };

  return (
    <div>
      <Routes>
        <Route path={getPathWithLanguage("/*")} element={<Wrapper />}>
          <Route index element={<Home />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="packaging" element={<Packaging />} />
          <Route path="refrigerators" element={<Refrigerators />} />
          <Route path="brands" element={<Brands />} />
          <Route path="blogItem" element={<BlogSingle />} />
          <Route path="contact-us" element={<Contacts />} />
          <Route path="ecosystem" element={<Ecosystem />} />
          <Route path="products" element={<Products />} />
          <Route path="*" element={<NothFoundPage />} />
        </Route>
        <Route path="*" element={<NothFoundPage />} />
      </Routes>
    </div>
  );
};

export default AppRouter;
