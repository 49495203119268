import React, { useEffect, useState } from "react";
import "./homevideo.css";

import PlayButton from "../../assets/home/play-button 1.png";
import VideoImg from "../../assets/home/vidyo.png";
import { useApp } from "../../hook/useApp";
import VideoModal from "./component";

const HomeVideo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else document.body.style.overflow = "visible";
  }, [isOpen]);
  const {
    optionInfo: { acf },
  } = useApp();
  return (
    <section className="video_section">
      <img
        src={acf?.video_image?.url}
        alt="video"
        className="video_coverimage"
      />
      <div className="button" onClick={openModal}>
        <img src={PlayButton} alt="button" />
      </div>
      {isOpen && <VideoModal setIsOpen={setIsOpen} />}
    </section>
  );
};

export default HomeVideo;
