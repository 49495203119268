import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { faqTradeBerry } from "services/contactServices";
import styles from "./style.module.css";

import { t } from "../../i18n";
import Accordion from "./components/Accordion/Accordion";
import { useApp } from "hook/useApp";

const Community = () => {
  const [faqInfo, setfaqInfo] = useState([]);
  const { language } = useApp();
  const faqInfoFetch = () => {
    faqTradeBerry(`?lang=${language}`)
      .then((res) => {
        setfaqInfo(res?.data);
      })
      .catch((err) => {
        
      });
  };

  useEffect(() => {
    faqInfoFetch();
  }, []);

  return (
    <section className={styles.communitySection}>
      <p className={styles.communityConentTitle}>{t("faq")} </p>
      <div className={styles.communityConent}>
        <div className={styles.communityConentLeft}>
          <h1 className={styles.title}>
            {t("what_would")}
            <br />
            {t("like_know")}
          </h1>
          <h1 className={styles.mobileTitle}>{t("what_would_know")}</h1>
        </div>
        <div className={styles.communityConentRight}>
          <Accordion faqInfo={faqInfo} />
        </div>
      </div>
    </section>
  );
};

export default Community;
