import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loading from "components/Loading";
import {
  oneProductImg,
  oneProductInfo,
  productPageInfo,
} from "services/contactServices";
import styles from "./style.module.css";

import ReadyToTake from "../../components/ReadyToTake/ReadyToTake";
import Fruits from "./components/Fruits";
import { useApp } from "hook/useApp";

const Products = () => {
  const [productInfo, setProductInfo] = useState([]);
  const [productPage, setProductPage] = useState({});
  const [productImgs, setProductImgs] = useState([]);
  const { language, loading } = useApp();

  const productFetch = async () => {
    try {
      const my_lng = language === "en" ? 141 : language === "hy" ? 506 : 507;

      const product_my = productPageInfo(my_lng);
      const one_product = language !== 'en' ? oneProductInfo(`?lang=${language}`) : oneProductInfo();
      
      const [productPageRes, oneProductRes] = await Promise.all([product_my, one_product]);



      const reverseData = oneProductRes?.data?.reverse();
      setProductInfo(reverseData);
      setProductPage(productPageRes?.data);

      for (const element of reverseData) {
        const result = element?._links?.["wp:featuredmedia"]?.[0]?.href;
        const cleanedResult = result.replace(/\/(ru|hy|en)\//, '/'); 
        const response = await oneProductImg(cleanedResult);
        setProductImgs((prev) => [...prev, response?.data?.guid?.rendered]);
      }
    } catch (err) {
      console.error(err, "err");
    }
  };
  useEffect(() => {
    productFetch();
  }, []);

  if (loading) {
   
    // return <Loading />;
  }
  return (
    <>
      <section
        className={styles.productsSectiontop}
        dangerouslySetInnerHTML={
          ({ __html: productPage?.excerpt?.rendered },
          { __html: productPage?.content?.rendered })
        }
      ></section>
      <section className={styles.productsSection}>
        <div className={styles.productsSectionBody}>
          {productInfo.map((fruit, index) => (
            <Fruits fruit={fruit} productImgs={productImgs} index={index} />
          ))}
        </div>
      </section>
      <ReadyToTake />
    </>
  );
};

export default Products;
