const PeachImage = () => {
	return (
		<svg width={'100%'} height={'100%'} viewBox='0 0 205 240' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				stroke='transparent'
				d='M168.354 78.721C160.076 72.5805 140.866 61.2991 111.219 69.2707C85.7449 71.3124 9.56306 130.459 93.1542 209.087C23.1307 153.782 49.7754 85.4381 103.112 68.5243C99.4571 66.8695 87.7561 63.016 67.0277 65.2191C55.45 65.7649 27.0019 76.4012 15.7181 117.086C13.7547 123.307 11.3535 141.192 17.4554 162.974C19.3173 170.991 29.8205 191.296 56.9381 208.379C63.7493 212.67 96.8534 220.358 104.688 227.767C105.082 228.343 105.672 229.419 106.375 228.72C109.07 224.741 117.263 218.125 136.166 214.013L136.211 213.998C155.174 207.549 189.021 196.039 196.36 152.851C198.648 139.392 200.335 104.917 168.354 78.721Z'
				fill='#191D29'
			/>
			<path
				stroke='transparent'
				d='M109.574 49.2147L109.284 58.6476L108.352 56.4892C107.944 60.2177 107.189 67.7042 107.427 67.8226C134.456 65.6507 153.951 60.3818 172.281 41.9626C174.446 39.4669 182.583 29.799 182.645 17.3885C182.647 16.9278 182.68 17.5817 182.659 17.1105C182.549 17.0791 182.439 17.0479 182.329 17.017C142.815 5.88521 125.309 23.0139 118.996 29.8738C116.325 33.143 110.701 41.588 109.574 49.2147Z'
				fill='#191D29'
			/>
			<path
				stroke='transparent'
				d='M93.6998 48.333C99.7259 54.1688 102.704 57.0916 105.11 66.0047C106.931 69.8038 110.271 68.2613 110.762 65.2016C111.145 63.7651 111.296 61.7627 111.214 59.5942C111.056 55.4219 108.945 51.6973 107.451 47.7984C107.192 47.1228 106.994 46.4605 106.902 45.8516C106.77 44.9824 107.098 44.1355 107.389 43.3057C108.261 40.8163 108.226 38.7988 107.264 38.0764C106.716 37.6648 104.825 37.0958 101.067 37.1869C97.4018 37.2757 93.2688 38.3581 91.8701 41.7471C91.0857 43.6478 91.242 45.9528 93.6998 48.333Z'
				fill='#191D29'
			/>
			<path
				d='M159.957 84.6156C151.679 78.475 132.469 67.1937 102.822 75.1652C77.348 77.2069 1.16609 136.354 84.7573 214.982C14.7337 159.677 41.3784 91.3327 94.7146 74.4188C91.0601 72.764 79.3591 68.9105 58.6307 71.1137C47.053 71.6594 18.6049 82.2957 7.32111 122.981C5.35775 129.201 2.95649 147.087 9.05838 168.868C10.9203 176.886 21.4235 197.191 48.5411 214.273C55.7135 218.792 88.4564 226.252 96.2912 233.661C96.6847 234.238 97.2753 235.314 97.9785 234.615C100.673 230.635 108.866 224.02 127.769 219.908L127.814 219.892C146.777 213.443 180.624 201.933 187.963 158.745C190.251 145.287 191.938 110.812 159.957 84.6156Z'
				strokeWidth='2.7115'
			/>
			<path
				d='M101.177 55.1093L100.887 64.5421L99.9547 62.3838C99.5472 66.1122 98.7917 73.5988 99.0296 73.7171C126.059 71.5452 145.554 66.2763 163.884 47.8572C166.049 45.3614 174.185 35.6935 174.248 23.283C174.25 22.8223 174.283 23.4763 174.262 23.005C174.152 22.9736 174.042 22.9425 173.932 22.9115C134.418 11.7797 116.912 28.9085 110.599 35.7683C107.928 39.0375 102.304 47.4825 101.177 55.1093Z'
				strokeWidth='2.7115'
			/>
			<path
				d='M85.3028 54.2276C91.329 60.0633 94.3071 62.9861 96.7126 71.8992C98.5338 75.6984 101.874 74.1559 102.365 71.0961C102.748 69.6596 102.899 67.6572 102.817 65.4887C102.659 61.3164 100.548 57.5918 99.0537 53.693C98.7948 53.0174 98.597 52.355 98.5045 51.7462C98.3725 50.8769 98.7014 50.03 98.992 49.2002C99.8636 46.7108 99.8285 44.6933 98.8669 43.9709C98.3189 43.5593 96.428 42.9904 92.67 43.0814C89.0048 43.1702 84.8718 44.2527 83.4731 47.6416C82.6887 49.5423 82.845 51.8474 85.3028 54.2276Z'
				strokeWidth='2.7115'
			/>
		</svg>
	);
};

export default PeachImage;
