import styles from './style.module.css';

import { FRUITS } from '../../configs';

const SvgComponent = ({ data, selected, selectedType, onSelect }) => {
	return (
		<svg
			className={styles.landSchemeMap}
			xmlns='http://www.w3.org/2000/svg'
			width='100%'
			height='100%'
			viewBox='0 0 400 400'
		>
			{data.elements.map((element, index) => {
				const key = `svg-element-${index}`;
				const { type, fruitId, ..._attributes } = element;

				const isSelected = fruitId === selectedType || selected === key;
				const selectedItem = isSelected && FRUITS.find(item => item.id === fruitId);

				const selectedColor = selectedItem?.color;

				const attributes = {
					..._attributes,
					onClick: () => fruitId && onSelect(key, fruitId),
					style: {
						cursor: fruitId && 'pointer',
						...(selectedColor
							? {
									fillOpacity: 1,
									fill: `var(${selectedColor})`,
									filter: `drop-shadow(0 0 2px var(${selectedColor}))`,
								}
							: fruitId === 0
								? {
										fill: '#027FFF',
										fillOpacity: 1,
									}
								: {}),
					},
				};

				switch (type) {
					case 'path':
						return <path key={key} {...attributes} />;
					case 'rect':
						return <rect key={key} {...attributes} />;
					case 'circle':
						return <circle key={key} {...attributes} />;
					case 'text':
						return <text key={key} {...attributes} />;
					default:
						return null;
				}
			})}
		</svg>
	);
};

export default SvgComponent;
