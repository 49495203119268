import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import styles from "./style.module.css";

import homeBg from "../../assets/home/bg_vidyo.jpg";
import productsImg from "../../assets/home/form_bg.png";
import { ReactComponent as Close } from "../../assets/SVGS/close.svg";
import GroupSvg from "../../assets/SVGS/GroupSvg";
import { ReactComponent as LogoSvg } from "../../assets/SVGS/LogoSvg.svg";
import Drawer from "../../components/Drawer/index";
import { useApp } from "../../hook/useApp";
import { t } from "../../i18n";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    onChangeLanguage,
    language,
    optionInfo: { acf },
    menuInfo,
  } = useApp();

  const { pathname } = useLocation();
  const { lng } = useParams();
  const location = useLocation();
  const path = location.pathname.slice(1);

  if (path.includes("blogItem")) {

  }
  const tittleName = () => {
    switch (pathname) {
      case `${language === "en" ? "" : `/${lng}`}/about-us`:
        return menuInfo?.[3]?.title;
      case `${language === "en" ? "" : `/${lng}`}/packaging`:
        return menuInfo?.[1]?.title;
      case `${language === "en" ? "" : `/${lng}`}/refrigerators`:
        return menuInfo?.[2]?.title;
      case `${language === "en" ? "" : `/${lng}`}/products`:
        return menuInfo?.[0]?.title;
      case `${language === "en" ? "" : `/${lng}`}/blog`:
        return menuInfo?.[4]?.title;
      case `${language === "en" ? "" : `/${lng}`}/contact-us`:
        return menuInfo?.[4]?.title ;
      default:
        return "";
    }
  };
  const getBackgroundImage = (path) => {
    switch (path) {
      case "about-us":
        return productsImg;
      case "contacts":
        return productsImg;
      case "blog":
        return productsImg;
      case "refrigerators":
        return productsImg;
      case "packaging":
        return productsImg;
      case "brands":
        return productsImg;
      case "ecosystem":
        return productsImg;
      case "products":
        return productsImg;
      case path.includes("blogItem"):
        return productsImg;
      default:
        return productsImg;
    }
  };
  const backgroundImage = getBackgroundImage(path);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isOpen]);

  const onOpenBurger = () => setIsOpen(!isOpen);
  return (
    <header className={styles.header}>
      {["/", "/hy", "/ru"].includes(pathname) && <img src={homeBg} alt="" />}
      <div
        className={
          ["/", "/hy", "/ru"].includes(pathname)
            ? styles.headerArea + " " + styles.homeHeaderArea
            : styles.headerAreas + " " + styles.HeaderAreas
        }
        style={{
          backgroundImage: !["/","/en", "/hy", "/ru"].includes(pathname)
            ? `url(${backgroundImage})`
            : "unset",
        }}
      >
        <div className={styles.headerTopContent}>
          <NavLink
            to={`/${language === "en" ? "" : language}`}
            className={styles.logoContent}
          >
            <div className={styles.logo}>
              <LogoSvg />
            </div>
            <GroupSvg className={styles.groupSvg} />
          </NavLink>
          <div className={styles.navContent}>
            <div className={styles.linksBox}>
              <NavLink to={"about-us"} className={styles.headerLinks}>
                {t("about_us")}
              </NavLink>
              <NavLink to={"packaging"} className={styles.headerLinks}>
                {t("packaging")}
              </NavLink>
              <NavLink to={"refrigerators"} className={styles.headerLinks}>
                {t("refrigerators")}
              </NavLink>
              <NavLink to={"products"} className={styles.headerLinks}>
                {t("products")}
              </NavLink>
              {/* <NavLink to={'blog'} className={styles.headerLinks} activeClass={styles.active}>
								{t('blog')}
							</NavLink> */}
              <NavLink to={"contact-us"} className={styles.headerLinks}>
                {t("contact")}
              </NavLink>
            </div>
            <div className={styles.langbox}>
              <span
                onClick={() => onChangeLanguage("hy")}
                style={{ color: language === "hy" ? "red" : "" }}
              >
                AM
              </span>
              <span
                onClick={() => onChangeLanguage("en")}
                style={{ color: language === "en" ? "red" : "" }}
              >
                EN
              </span>
              <span
                onClick={() => onChangeLanguage("ru")}
                style={{ color: language === "ru" ? "red" : "" }}
              >
                RU
              </span>
            </div>
            <div className={styles.rightContent}>
              <div className={styles.getInTouch}>
                <Link to="/contact-us">{t("get_in_touch")}</Link>
              </div>
              <span className={styles.ractangle}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="17"
                  viewBox="0 0 2 18"
                  fill="none"
                >
                  <path
                    d="M0.606934 0.459473V17.4595"
                    stroke="white"
                    strokeOpacity="0.3"
                  />
                </svg>
              </span>
              <span
                // onTouchStart={() => setIsOpen(!isOpen)}
                // onclick = "void(0)"
                onClick={onOpenBurger}
                style={{ cursor: "pointer" }}
                className={styles.burgerMenuBtn}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="16"
                  viewBox="0 0 22 16"
                  fill="none"
                >
                  <path
                    d="M0.608401 0.927347C0.609209 0.488841 0.964916 0.133789 1.40342 0.133789H20.598C21.0371 0.133789 21.3931 0.489733 21.3931 0.928812C21.3931 1.36789 21.0371 1.72383 20.598 1.72383H1.40342C0.963772 1.72383 0.607591 1.367 0.608401 0.927347Z"
                    fill="white"
                  />
                  <path
                    d="M0.608401 7.92735C0.609209 7.48884 0.964916 7.13379 1.40342 7.13379H20.598C21.0371 7.13379 21.3931 7.48973 21.3931 7.92881C21.3931 8.36789 21.0371 8.72383 20.598 8.72383H1.40342C0.963772 8.72383 0.607591 8.367 0.608401 7.92735Z"
                    fill="white"
                  />
                  <path
                    d="M0.608401 14.9889C0.609209 14.5504 0.964916 14.1953 1.40342 14.1953H20.598C21.0371 14.1953 21.3931 14.5513 21.3931 14.9903C21.3931 15.4294 21.0371 15.7854 20.598 15.7854H1.40342C0.963772 15.7854 0.607591 15.4285 0.608401 14.9889Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
        {["/", "/hy", "/ru"].includes(pathname) && !isOpen ? (
          <div className={styles.headerMainContent}>
            <div className={styles.discoverFreshness}>
              <h1>{acf?.header_title}</h1>
            </div>
            <div className={styles.headerMainContentRight}>
              <p>{acf?.header_description}</p>
              <NavLink to={"about-us"} className={styles.aboutUsBtn}>
                <span>{t("about_us")}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                >
                  <path
                    d="M15.5303 6.53033C15.8232 6.23744 15.8232 5.76256 15.5303 5.46967L10.7574 0.6967C10.4645 0.403807 9.98959 0.403807 9.6967 0.6967C9.40381 0.989593 9.40381 1.46447 9.6967 1.75736L13.9393 6L9.6967 10.2426C9.40381 10.5355 9.40381 11.0104 9.6967 11.3033C9.98959 11.5962 10.4645 11.5962 10.7574 11.3033L15.5303 6.53033ZM-6.55671e-08 6.75L15 6.75L15 5.25L6.55671e-08 5.25L-6.55671e-08 6.75Z"
                    fill="white"
                  />
                </svg>
              </NavLink>
            </div>
          </div>
        ) : (
          <div
            className={styles.headerMainContent}
            style={{ height: "-webkit-fill-available" }}
          >
            {window.innerWidth > 768 ? (
              <h2
                className={styles.pageTitle}
                style={{
                  fontSize:
                    path.replace(/[^a-zA-Z ]/g, " ").includes("blogItem") &&
                    "54px",
                  textTransform:
                    path.replace(/[^a-zA-Z ]/g, " ").includes("blogItem") &&
                    "none",
                }}
              >
                {t(tittleName())}
              </h2>
            ) : (
              <h2
                className={styles.pageTitle}
                style={{
                  fontSize:
                    path.replace(/[^a-zA-Z ]/g, " ").includes("blogItem") &&
                    "28px",
                  textTransform:
                    path.replace(/[^a-zA-Z ]/g, " ").includes("blogItem") &&
                    "none",
                }}
              >
                {t(tittleName())}
              </h2>
            )}
          </div>
        )}
      </div>

      <Drawer isOpen={isOpen}>
        <header className="drawer__header">
          <NavLink to="/" className={styles.logoContent}>
            <div className={styles.logo}>
              <LogoSvg />
            </div>
            <GroupSvg className={styles.groupSvg} />
          </NavLink>
          <div className={styles.rightContent}>
            <div className="getInTouch" onClick={() => setIsOpen(false)}>
              <NavLink to="/contact-us">{t("get_in_touch")}</NavLink>
            </div>
            <span className={styles.ractangle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="17"
                viewBox="0 0 2 18"
                fill="none"
              >
                <path
                  d="M0.606934 0.459473V17.4595"
                  stroke="white"
                  strokeOpacity="0.3"
                />
              </svg>
            </span>
            <span
              className={styles.burgerMenuBtn}
              onClick={() => setIsOpen(!isOpen)}
            >
              <Close />
            </span>
          </div>
        </header>
        <div className="drawer__list">
          <NavLink
            onClick={() => setIsOpen(false)}
            to={"about-us"}
            className={styles.headerLinks}
            activeClass={styles.active}
          >
            {t("about_us")}
          </NavLink>
          <NavLink
            onClick={() => setIsOpen(false)}
            to={"packaging"}
            className={styles.headerLinks}
            activeClass={styles.active}
          >
            {t("packaging")}
          </NavLink>
          <NavLink
            onClick={() => setIsOpen(false)}
            to={"refrigerators"}
            className={styles.headerLinks}
            activeClass={styles.active}
          >
            {t("refrigerators")}
          </NavLink>
          <NavLink
            onClick={() => setIsOpen(false)}
            to={"products"}
            className={styles.headerLinks}
            activeClass={styles.active}
          >
            {t("products")}
          </NavLink>
          <NavLink
            onClick={() => setIsOpen(false)}
            to={"contact-us"}
            className={styles.headerLinks}
            activeClass={styles.active}
          >
            {t("contact")}
          </NavLink>
        </div>
        <div className="drawer_lang">
          <span onClick={() => onChangeLanguage("hy")}>HY</span>
          <span onClick={() => onChangeLanguage("en")}>EN</span>
          <span onClick={() => onChangeLanguage("ru")}>RU</span>
        </div>
      </Drawer>
    </header>
  );
};

export default Header;
