const NectarImage = () => {
	return (
		<svg width={'100%'} height={'100%'} viewBox='0 0 280 284' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				stroke='transparent'
				d='M138.033 241.042C108.368 233.929 57.9875 205.118 54.6429 148.387C53.3445 117.6 72.2002 95.6887 98.8598 84.5588C99.9091 84.1207 101.031 83.8749 102.157 83.7163C103.363 83.5463 104.059 83.5494 107.231 85.0219L118.327 92.1493C121.18 93.8972 127.622 98.368 130.459 104.354C132.133 107.886 141.192 112.52 152.449 114.251C166.662 115.271 175.686 117.653 209.218 104.47C216.168 112.205 220.191 129.199 221.873 153.253C222.086 156.309 222.085 187.373 204.04 214.765C198.195 221.133 183.866 238.983 159.982 236.815C158.677 236.697 157.39 236.159 156.33 235.389C55.2291 161.955 100.349 119.755 100.349 119.755C93.5674 125.665 58.341 165.856 154.123 236.428C154.816 236.939 154.72 238.054 153.919 238.37C149.667 240.045 143.511 242.355 138.033 241.042Z'
				fill='#191D29'
			/>
			<path
				stroke='transparent'
				d='M104.49 80.1068C104.49 80.1068 104.49 80.1056 104.492 80.1044L107.64 76.1079C109.904 72.0489 112.143 65.8388 114.493 57.6888C116.842 49.5388 117.045 48.447 120.866 42.4034C139.623 19.8282 164.953 13.5426 185.908 12.6377C187.275 22.3786 187.703 44.5388 178.794 56.7184C164.678 49.9172 123.743 70.933 114.296 76.0731C123.687 71.0439 164.229 50.8602 179.244 57.6694C180.771 58.3618 184.238 60.317 191.262 64.8464C199.958 70.8839 215.407 86.6335 231.583 86.4075C229.219 90.4228 216.691 101.537 186.787 109.643C172.187 113.601 141.428 116.819 130.483 99.6963C128.535 96.6493 124.377 92.1514 117.33 87.7159C105.166 80.0602 104.526 80.0823 104.492 80.1044L104.49 80.1068Z'
				fill='#191D29'
			/>
			<path
				d='M114.427 114.283C117.281 116.031 114.152 112.241 116.989 118.227C118.663 121.759 127.722 126.393 138.98 128.124C153.193 129.144 162.216 131.526 195.749 118.343C202.699 126.078 206.721 143.072 208.403 167.126C208.617 170.182 208.615 201.246 190.571 228.638C184.725 235.006 170.396 252.856 146.512 250.688C145.207 250.57 143.92 250.032 142.86 249.262C41.7593 175.828 86.879 133.628 86.879 133.628M114.427 114.283L99.1599 123.476L91.3192 129.08L86.879 133.628M114.427 114.283V114.283C112.294 111.298 109.658 108.707 106.637 106.627L105.651 105.948L93.7615 98.895C90.589 97.4224 89.8936 97.4194 88.6874 97.5893C87.5614 97.748 86.4393 97.9938 85.39 98.4318C58.7305 109.562 39.8748 131.473 41.1731 162.26C44.5177 218.991 94.8981 247.802 124.563 254.915C130.041 256.228 136.198 253.918 140.449 252.243C141.25 251.927 141.346 250.812 140.653 250.301C44.8712 179.729 80.0977 139.538 86.879 133.628'
				strokeWidth='2.7115'
			/>
			<path
				d='M90.0123 95.1621C90.0123 95.1621 88.0314 95.61 102.282 103.031C108.971 106.513 115.178 112.264 115.88 114.707C120.519 130.823 156.695 130.266 171.294 126.308C201.199 118.202 215.749 104.297 218.114 100.282C201.938 100.508 186.489 84.7584 177.792 78.7208C170.768 74.1915 167.302 72.2363 165.775 71.5439C150.423 64.5823 108.389 85.8354 100.243 90.262C99.5916 90.6159 99.6031 90.6186 100.252 90.2613C108.471 85.7391 150.9 63.643 165.324 70.5929C174.233 58.4133 173.805 36.2531 172.438 26.5122C151.483 27.4171 126.153 33.7026 107.396 56.2779C103.576 62.3215 103.372 63.4132 101.023 71.5633C98.6735 79.7133 96.4341 85.9233 94.1706 89.9824C91.0205 93.9812 90.0123 95.1621 90.0123 95.1621Z'
				strokeWidth='2.7115'
			/>
		</svg>
	);
};

export default NectarImage;
