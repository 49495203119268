const PlumImage = () => {
	return (
		<svg width={'100%'} height={'100%'} viewBox='0 0 274 294' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				stroke='transparent'
				d='M51.5289 54.8486C68.1945 39.5772 96.1344 45.0769 110.926 51.7793C108.353 61.6172 94.8915 80.0157 77.9429 89.8472C71.706 93.4652 53.6721 99.2023 27.4628 100.294C28.9192 91.7199 38.848 66.4687 51.5289 54.8486Z'
				fill='#191D29'
			/>
			<path
				stroke='transparent'
				d='M131.924 66.4746C132.16 67.3592 128.7 68.7433 127.061 68.2559C124.445 56.5501 124.647 49.9111 126.211 42.8122C126.211 42.5275 126.104 42.0514 125.681 42.4238C124.379 44.066 119.206 48.2913 111.166 52.3237C109.493 54.3345 69.8485 65.9407 46.6453 84.5774C70.7925 63.1088 109.419 52.2252 110.675 50.9882C125.103 42.4411 128.239 34.4559 132.175 27.809C132.643 26.8605 136.477 26.9328 139.295 31.3391C139.39 31.9518 139.922 33.0517 137.305 36.3018C134.224 41.5351 128.834 54.8963 131.924 66.4746Z'
				fill='#191D29'
			/>
			<path
				stroke='transparent'
				d='M169.559 62.6367C161.352 60.5922 144.958 61.1196 130.633 68.4635C69.8405 86.9217 68.869 150.984 78.8644 181.352C86.8289 205.55 113.743 242.836 151.617 246.461C167.991 248.735 178.883 247.366 185.319 245.137C189.945 243.536 196.198 241.45 200.861 239.961C208.382 237.559 219.591 230.54 231.456 214.035C240.041 203.005 254.736 168.14 236.617 122.062C230.456 106.902 212.541 72.3391 169.559 62.6367Z'
				fill='#191D29'
			/>
			<path
				d='M40.217 68.7822C56.8825 53.5108 84.8224 59.0105 99.6145 65.7129C97.0411 75.5508 83.5796 93.9492 66.6309 103.781C60.394 107.399 42.3602 113.136 16.1508 114.228C17.6072 105.653 27.536 80.4023 40.217 68.7822Z'
				strokeWidth='2.7115'
			/>
			<path
				d='M100.114 66.1342C98.4417 68.145 58.7968 79.7512 35.5935 98.388C59.7408 76.9194 98.3674 66.0357 99.6236 64.7988C114.052 56.2516 117.187 48.2664 121.123 41.6196C121.592 40.671 125.425 40.7433 128.243 45.1496C128.338 45.7623 128.87 46.8622 126.254 50.1124C123.172 55.3457 117.782 68.7068 120.873 80.2852C121.109 81.1698 117.648 82.5539 116.009 82.0665C113.393 70.3607 113.595 63.7217 115.16 56.6227C115.159 56.3381 115.052 55.8619 114.629 56.2344C113.327 57.8766 108.154 62.1018 100.114 66.1342ZM100.114 66.1342C100.113 66.1346 100.113 66.1346 100.113 66.1346'
				strokeWidth='2.7115'
			/>
			<path
				d='M158.506 76.4472C150.299 74.4028 133.905 74.9302 119.58 82.2741C58.7875 100.732 57.816 164.795 67.8115 195.163C75.776 219.36 102.69 256.646 140.564 260.272C156.938 262.546 167.83 261.176 174.266 258.948C178.892 257.346 185.145 255.261 189.808 253.772C197.329 251.37 208.538 244.35 220.403 227.846C228.988 216.816 243.683 181.95 225.564 135.872C219.403 120.713 201.488 86.1497 158.506 76.4472Z'
				strokeWidth='2.7115'
			/>
		</svg>
	);
};

export default PlumImage;
