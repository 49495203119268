export class CustomCarousel {
	constructor(options) {
		this.init(options);
		this.addListeners();
	}

	init(options) {
		this.node = options.node;
		this.node.slider = this;
		this.slides = Array.from(this.node.querySelector('.slides').children);
		this.slidesN = this.slides.length;
		this.pagination = this.node.querySelector('.pagination');
		this.pagTransf = 'translate( -50%, -50% )';
		this.dots = Array.from(this.pagination.children);
		this.dotsN = this.dots.length;
		this.step = -360 / this.dotsN;
		this.angle = window.matchMedia('(max-width: 768px)').matches ? -180 : -100;
		this.activeN = 0;
		this.prevN = this.activeN;
		this.speed = options.speed || 300;

		this.setSlide(this.activeN);
		this.arrangeDots();
		this.pagination.style.transitionDuration = `${this.speed}ms`;

		this.setter = i => {
			this.setSlide(i);
		};
	}

	addListeners() {
		this.dots.forEach((dot, i) => {
			dot.addEventListener('click', () => {
				this.setSlide(i);
			});
		});
	}

	setSlide(slideN) {
		this.prevN = this.activeN;
		this.activeN = slideN;

		this.dots[this.prevN].classList.remove('active');
		this.slides[this.prevN].classList.remove('active');

		this.dots[this.activeN].classList.add('active');
		this.slides[this.activeN].classList.add('active');

		this.rotate();
	}

	rotate() {
		if (this.activeN < this.dotsN) {
			this.angle += ((dots, next, prev, step) => {
				let inc,
					half = dots / 2;
				if (prev > dots) prev = dots - 1;
				if (Math.abs((inc = next - prev)) <= half) return step * inc;
				if (Math.abs((inc = next - prev + dots)) <= half) return step * inc;
				if (Math.abs((inc = next - prev - dots)) <= half) return step * inc;
			})(this.dotsN, this.activeN, this.prevN, this.step);

			this.pagination.style.transform = `${this.pagTransf}rotate(${this.angle}deg)`;
		}
	}

	arrangeDots() {
		this.dots.forEach((dot, i) => {
			dot.style.transform = `rotate(${(360 / this.dotsN) * i}deg)`;
		});
	}
}
// transform: translate(-50%, -50%) rotate(100deg);
