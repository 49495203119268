import React, { useRef } from "react";
import MySlider from "./components/Myslider/MySlider";
import { useApp } from "../../hook/useApp";
import { t } from "../../i18n";
import styles from "./style.module.css";

const WhyUs = () => {
  const slider = useRef();

  const next = () => {
    slider.current.slickNext();
  };
  const previous = () => {
    slider.current.slickPrev();
  };

  const {
    optionInfo: { acf },
  } = useApp();

  return (
    <section className={styles.whyUsSecion}>
      <div className={styles.whyUsContent}>
        <div className={styles.whyUsContentLeft}>
          <p className={styles.whyUsContentLeftTitle}>{t("why_tradeberry")}</p>
          <div className={styles.whyUsContentLeftConent}>
            <div
              dangerouslySetInnerHTML={{
                __html: acf?.why_tradeberry_description,
              }}
            ></div>
          </div>
        </div>
        <div className={styles.whyUsContentRight}>
          <div className={styles.whyUsContentRightTop}>
            <div className={styles.contentRightTitle}>
              <h1 className={styles.whyUsContentRightTitle}>
                {t("experience_delight")} <br></br>
                {t("natural_harvests")}
              </h1>
              <h1 className={styles.whyUsContentRightTitleMobile}>
                {acf?.why_tradeberry_title}
              </h1>
            </div>

            <div className={styles.sliderBtns}>
              <span
                className={styles.btnLeft + " " + styles.arrowspan}
                onClick={previous}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="13"
                  viewBox="0 0 16 13"
                  fill="none"
                >
                  <path
                    d="M15.5303 7.03033C15.8232 6.73744 15.8232 6.26256 15.5303 5.96967L10.7574 1.1967C10.4645 0.903807 9.98959 0.903807 9.6967 1.1967C9.40381 1.48959 9.40381 1.96447 9.6967 2.25736L13.9393 6.5L9.6967 10.7426C9.40381 11.0355 9.40381 11.5104 9.6967 11.8033C9.98959 12.0962 10.4645 12.0962 10.7574 11.8033L15.5303 7.03033ZM-6.55671e-08 7.25L15 7.25L15 5.75L6.55671e-08 5.75L-6.55671e-08 7.25Z"
                    fill="white"
                  />
                </svg>
              </span>
              <span
                className={styles.btnRight + " " + styles.arrowspan}
                onClick={next}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="13"
                  viewBox="0 0 16 13"
                  fill="none"
                >
                  <path
                    d="M15.5303 7.03033C15.8232 6.73744 15.8232 6.26256 15.5303 5.96967L10.7574 1.1967C10.4645 0.903807 9.98959 0.903807 9.6967 1.1967C9.40381 1.48959 9.40381 1.96447 9.6967 2.25736L13.9393 6.5L9.6967 10.7426C9.40381 11.0355 9.40381 11.5104 9.6967 11.8033C9.98959 12.0962 10.4645 12.0962 10.7574 11.8033L15.5303 7.03033ZM-6.55671e-08 7.25L15 7.25L15 5.75L6.55671e-08 5.75L-6.55671e-08 7.25Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
          </div>
          <MySlider slider={slider} />
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
