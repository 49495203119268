import React, { useEffect, useState } from 'react';
import './modal.css';

import { useApp } from '../../../hook/useApp';

const VideoModal = ({ setIsOpen }) => {
	const {
		language,
		optionInfo: { acf },
	} = useApp();
	
	return (
		<div
			className='modal'
			onClick={e => {
				e.stopPropagation();
				setIsOpen(false);
			}}
		>
			<div className='modal__container' dangerouslySetInnerHTML={{ __html: acf?.video_url }}>
				{/* {language === 'hy' && (
					<iframe
						height='315'
						src='https://www.youtube.com/embed/Rg6EmEWhvbo?si=0HnzPkCkpH2I9Czy'
						title='YouTube video player'
						frameborder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						allowfullscreen
					></iframe>
				)}
				{language === 'ru' && (
					<iframe
						height='315'
						src='https://www.youtube.com/embed/pufSL_f-fIQ?si=RkmXAOFMkhltTCfn'
						title='YouTube video player'
						frameborder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						allowfullscreen
					></iframe>
				)}
				{language === 'en' && (
					<iframe
						width='560'
						height='315'
						src='ttps://www.youtube.com/watch?si=FbC4LyYBwNbYTj59'
						title='YouTube video player'
						frameborder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						allowfullscreen
					></iframe>
				)} */}
			</div>
		</div>
	);
};

export default VideoModal;
