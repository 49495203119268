import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Loading from "components/Loading";
import { contactInfo } from "services/contactServices";
import styles from "./style.module.css";
import Modal from "components/Modal/modal";
import Facebook from "../../assets/SVGS/FacebookLogo.svg";
import Instagram from "../../assets/SVGS/InstagramLogo.svg";
import { ReactComponent as MapIcon } from "../../assets/SVGS/mapIcon.svg";
import Phone from "../../assets/SVGS/phone.svg";
import Brands from "../../components/Brands/Brands";
import Spinner from "../../components/spinner/index";
import { useApp } from "../../hook/useApp";
import { t } from "../../i18n";
import { Contact } from "../../services/contactServices";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import axios from "axios";

const Contacts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorData, setErrorData] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    loading,
  } = useForm();
  const { language } = useApp();

  const contactFetch = () => {
    contactInfo(`?lang=${language}`)
      .then((res) => {
       
      })
      .catch((err) => {
        
      });
  };

  useEffect(() => {
    contactFetch();
  }, []);

  const {
    optionInfo: { acf },
  } = useApp();
  const libraries = ["places"];
  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };
  const center = {
    lat: 40.1318148, // default latitude
    lng: 44.474589, // default longitude
  };
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCvSX8u70NrPlaxhLF5vNGXGezdTNlJhYI",
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  const onSubmit = async (data) => {
    data.subject = "contact";
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("message", data.message);
      formData.append("fullname", data.name);
      formData.append("email", data.email);
      formData.append("contact", data.phone);
      formData.append("subject", data.subject);
      formData.append("_wpcf7_unit_tag", "0b3d22f");
      const response = await axios.post(
        "https://testn.top1.am/wp-json/contact-form-7/v1/contact-forms/165/feedback",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Basic YWRtaW46YWRtaW4=",
          },
        }
      );
 
      if (response.data.status === "mail_sent") {
        setIsModalOpen(true); // Открыть модальное окно при успешной отправке
      } else {
       
      }
    } catch (err) {
      
    } finally {
      setIsLoading(false);
    }
  };
  if (loading) {
    // return <Loading />;
  }
  return (
    <section className={styles.contacsSection}>
      <div className={styles.contactsContent}>
        <div className={styles.mapContent}>
          <div className={styles.cart}>
            <div className={styles.cartTexts}>
              <h1 className={styles.cartTitle}>{t('contact_us')}</h1>
              <span>{acf?.address}</span>
              <div className={styles.phoneNumber}>
                <span className={styles.phone}>
                  <a href={`tel:${styles.phoneNumber}`}>
                    <img src={Phone} alt="phone" />
                  </a>
                </span>
                <div>
                  <div className={styles.phonenumbertext}>
                    <a href={`tel:${acf?.telephone}`}>
                      <span>{acf?.telephone}</span>
                    </a>
                  </div>
                  <div className={styles.phonenumbersubtext}>
                    <span>{acf?.work_time}</span>
                  </div>
                </div>
              </div>
              <div className={styles.social}>
                <span>
                  <a href={acf?.instagram_url}>
                    <img src={Instagram} alt="Instagram" />
                  </a>
                </span>
                <span>
                  <a href={acf?.facebook_url}>
                    <img src={Facebook} alt="Facebook" />
                  </a>
                </span>
              </div>
            </div>
          </div>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={14}
            center={center}
          >
            <Marker
              position={center}
              options={{
                icon: <MapIcon />,
              }}
            />
          </GoogleMap>
        </div>
        <div className={styles.contentRight}>
          <p className={styles.contentRightTitle}>{t("reach_out")}</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.formBox}>
              <div className={styles.inputBox}>
                <label htmlFor="name">{t("Your_name")}</label>
                <input
                  {...register("name", { required: true })}
                  type="text"
                  id="name"
                  name="name"
                  placeholder={t("Your_first_name")}
                />
                {errorData?.name ? (
                  <p>{errorData?.name[0]}</p>
                ) : errors?.name ? (
                  <p>{t("error_req")}</p>
                ) : (
                  ""
                )}
              </div>
              <div className={styles.inputBox}>
                <label htmlFor="address">{t("Your_email_address")}</label>
                <input
                  {...register("email", {
                    required: true,
                    pattern: {
                      value:
                        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
                    },
                  })}
                  type="email"
                  id="address"
                  placeholder={t("Your_email_address")}
                />
                {errorData?.email ? (
                  <p>{errorData?.email[0]}</p>
                ) : errors?.email ? (
                  <p>{t("error_req")}</p>
                ) : (
                  ""
                )}
              </div>
              <div className={styles.inputBox}>
                <label htmlFor="phoneNumber">{t("Your_phone_number")}</label>
                <input
                  {...register("phone")}
                  type="phone"
                  id="phoneNumber"
                  placeholder={t("Your_phone_number")}
                />
                {errorData?.phone ? (
                  <p>{errorData?.phone[0]}</p>
                ) : errors?.phone ? (
                  <p>{t("error_req")}</p>
                ) : (
                  ""
                )}
              </div>
              <div className={styles.inputBox}>
                <label htmlFor="message">{t("Your_message")}</label>
                <textarea
                  {...register("message", { required: false })}
                  id="message"
                  name="message"
                  placeholder={t("hi")}
                ></textarea>
              </div>
              <div className={styles.inputBoxCheckbox}>
                <input
                  type="checkbox"
                  className={`${errors?.terms || errorData?.terms ? styles.inputError : ""}`}
                  {...register("terms", { required: false })}
                  id="PrivacyPolicy"
                  name="PrivacyPolicy"
                />
                <label htmlFor="PrivacyPolicy">{t("by_subscription")}</label>
              </div>

              <div>
                <button className={styles.formButton}>
                  {!isLoading ? (
                    <>
                      {t("send_now")}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="13"
                        viewBox="0 0 16 13"
                        fill="none"
                      >
                        <path
                          d="M15.5303 7.03033C15.8232 6.73744 15.8232 6.26256 15.5303 5.96967L10.7574 1.1967C10.4645 0.903807 9.98959 0.903807 9.6967 1.1967C9.40381 1.48959 9.40381 1.96447 9.6967 2.25736L13.9393 6.5L9.6967 10.7426C9.40381 11.0355 9.40381 11.5104 9.6967 11.8033C9.98959 12.0962 10.4645 12.0962 10.7574 11.8033L15.5303 7.03033ZM-6.55671e-08 7.25L15 7.25L15 5.75L6.55671e-08 5.75L-6.55671e-08 7.25Z"
                          fill="white"
                        />
                      </svg>
                    </>
                  ) : (
                    <Spinner color="#008210" />
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Brands />
      {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />}
    </section>
  );
};

export default Contacts;
