import Loading from "components/Loading";
import Brands from "../../components/Brands/Brands";
import Certificates from "../../components/Certificates/Certificates";
import Community from "../../components/Community/Community";
import HomeVideo from "../../components/HomeVideo";
import InteractiveMap from "../../components/InteractiveMap/InteractiveMap";
import ReadyToTake from "../../components/ReadyToTake/ReadyToTake";
import StayInKnow from "../../components/StayInKnow/StayInKnow";
import WhyUs from "../../components/whyUsSection/WhyUs";
import { useApp } from "hook/useApp";

const Home = () => {
  const { loading } = useApp();

  if (loading) {
    // return <Loading />;
  }

  return (
    <>
      <WhyUs />
      <InteractiveMap />
      <StayInKnow />
      <HomeVideo />
      <Community />
      <ReadyToTake />
      <Certificates />
      <Brands />
    </>
  );
};

export default Home;
