import React, { useEffect, useState } from "react";
import Loading from "components/Loading";
import { packaging } from "services/contactServices";
import styles from "./style.module.css";

import ReadyToTake from "../../components/ReadyToTake/ReadyToTake";
import { useApp } from "hook/useApp";

const Packaging = () => {
  const [packagingInfo, setPackagingInfo] = useState([]);
  const { language, loading } = useApp();
  const packagingFetch = () => {
    const my_lng = language === "en" ? 137 : language === "hy" ? 517 : 518;

    packaging(my_lng)
      .then((res) => {
        setPackagingInfo(res?.data);
      })
      .catch((err) => {
       
      });
  };

  useEffect(() => {
    packagingFetch();
  }, []);
  if (loading) {
    // return <Loading />;
  }

  return (
    <section className={styles.packagingSection}>
      {packagingInfo.length !== 0 && (
        <div className={styles.packagingContent}>
          {/* <h1
					className={styles.packagingContentTitle}
					dangerouslySetInnerHTML={{ __html: packagingInfo?.content?.rendered }}
				/> */}
          <div className={styles.imagesbox}>
            <div className={styles.imgBox}>
              <img src={packagingInfo?.acf?.photo_one?.url} alt="" />
            </div>
            <div className={styles.imgBox}>
              <img src={packagingInfo?.acf?.photo_two?.url} alt="" />
            </div>
            <div className={styles.imgBox}>
              <img src={packagingInfo?.acf?.photo_three?.url} alt="" />
            </div>
          </div>
          <div
            className={styles.flexContent}
            dangerouslySetInnerHTML={{
              __html: packagingInfo?.content?.rendered,
            }}
          ></div>
        </div>
      )}
      <ReadyToTake />
    </section>
  );
};

export default Packaging;
