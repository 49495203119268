import React, { useRef } from 'react';
import styles from './styles.module.css';

const AccordionItem = ({ question, answer, isOpen, onClick }) => {
	const contentHeight = useRef();
	return (
		<div className={styles.wrapper}>
			<div className={styles.accardionHead}>
				<p className={styles.questionContent} onClick={onClick}>
					{question}
				</p>
				<span className={[styles.questionContainer, isOpen && styles.active].join(' ')} onClick={onClick}>
					{/* <RiArrowDropDownLine className={`arrow ${isOpen ? 'active' : ''}`} />  */}
					<div className={styles.accardionToggler}>
						<div className={styles.accardionTogglerPlus} style={{ display: isOpen ? 'none' : 'block' }}>
							<svg xmlns='http://www.w3.org/2000/svg' width='34' height='35' viewBox='0 0 34 35' fill='none'>
								<path
									d='M19.6043 14.969H34V20.1033H19.6043V35H14.3957V20.1033H0V14.969H14.3957V0H19.6043V14.969Z'
									fill='#E8EAE8'
								/>
							</svg>
						</div>
						<div className={styles.accardionTogglerMinus} style={{ display: isOpen ? 'block' : 'none' }}>
							<svg xmlns='http://www.w3.org/2000/svg' width='34' height='35' viewBox='0 0 34 35' fill='none'>
								<rect x='0' y='15' width='34' height='5' rx='2.5' fill='#3FAA0B' />
							</svg>
						</div>
					</div>
				</span>
			</div>
			<div
				ref={contentHeight}
				className={styles.answerContainer}
				style={{
					visibility: isOpen ? 'visible' : 'hidden',
					overflow: isOpen ? 'visible' : 'hidden',
					opacity: isOpen ? '1' : '0',
					height: isOpen ? contentHeight.current.scrollHeight : '0px',
					marginTop: isOpen ? '20px' : '0',
				}}
				dangerouslySetInnerHTML={{ __html: answer }}
			></div>
		</div>
	);
};

export default AccordionItem;
