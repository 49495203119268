import React, { useState } from "react";
import styles from "../../style.module.css";

import { t } from "../../../../i18n";
import AccordionItem from "../AccordionItem/AccordionItem";
const Accordion = ({ faqInfo }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  // const data = [
  // 	{
  // 		question: t('orchards_located'),
  // 		answer: t('orchards_located_answer'),
  // 	},
  // 	{
  // 		question: t('what_fruits'),
  // 		answer: t('offers_a_diverse'),
  // 	},

  // 	{
  // 		question: t('orchards_protected'),
  // 		answer: t('implement_advanced'),
  // 	},
  // 	{
  // 		question: t('measures_does'),
  // 		answer: t('packaging_techniques'),
  // 	},
  // 	{
  // 		question: t('preserve_fruit'),
  // 		answer: t('including_atmosphere'),
  // 	},
  // 	{
  // 		question: t('packaging_technologies'),
  // 		answer: t('innovative_packaging'),
  // 	},
  // 	{
  // 		question: t('contribution_to'),
  // 		answer: t('our_efforts'),
  // 	},
  // ];
  return (
    <div className={styles.accordion}>
      {faqInfo.map((item, index) => (
        <AccordionItem
          key={index}
          question={item?.title?.rendered}
          answer={item?.content?.rendered}
          isOpen={activeIndex === index}
          onClick={() => handleItemClick(index)}
        />
      ))}
    </div>
  );
};

export default Accordion;
