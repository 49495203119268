import React from 'react';
import styles from './style.module.css';

const SlideItem = ({ itemData, index, whyTradeBerryIcon }) => {
	return (
		<div className={`${styles.slideItem} `}>
			<div className={styles.slideItemTop}>
				<div className={styles.slideItemTopBox}>
					<img src={whyTradeBerryIcon?.[index]?.source_url} alt='' />
				</div>
			</div>
			<div className={styles.slideItembody}>
				<div className={styles.slideTitleContainer}>
					<h3 className={styles.slideItembodyTitle}>{itemData?.title?.rendered}</h3>
				</div>
				<p className={styles.slideItemConentTitle}>{itemData?.acf?.home_green_title}</p>
				<div className={styles.slideItemConent} dangerouslySetInnerHTML={{ __html: itemData?.excerpt?.rendered }}></div>
			</div>
		</div>
	);
};

export default SlideItem;
