import './nothfound.scss';

const NothFoundPage = () => {
	return (
		<div className='nothfound'>
			<h1>404</h1>
			<p>Not Found Page</p>
		</div>
	);
};

export default NothFoundPage;
