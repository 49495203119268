const FlatPeachImage = () => {
	return (
		<svg width={'100%'} height={'100%'} viewBox='0 0 291 226' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path
				stroke='transparent'
				d='M51.0325 105.406C57.3332 96.3754 103.199 100.572 117.629 59.9508C128.446 62.3189 204.412 61.2134 189.632 146.161C203.91 98.873 184.523 60.7762 137.207 55.047C142.898 51.3993 168.94 42.9753 178.517 43.5488C190.496 44.2662 227.6 42.6364 249.687 67.9566C258.106 77.6074 266.419 89.0313 267.985 108.628C269.1 122.584 263.43 134.582 255.701 143.217C246.031 154.022 224.739 163.748 200.317 165.7C193.326 165.219 176.531 175.624 164.21 184.635C151.888 193.646 128.723 204.677 96.0663 198.107C46.1072 188.055 41.0332 161.377 40.4105 129.322C40.2698 122.078 40.6649 113.758 51.0325 105.406Z'
			/>
			<path
				stroke='transparent'
				d='M107.887 16.0678C115.024 17.5041 121.86 25.0022 117.653 28.8492C113.028 33.7333 123.479 42.5925 120.267 53.2961L120.225 53.4382C115.984 67.5718 110.102 87.1747 61.6785 100.543C41.0663 106.233 43.854 116.012 9.72344 115.73C30.2592 96.5326 14.6901 83.7321 42.2515 51.9279C47.2108 47.9091 57.4052 36.2741 66.7993 29.849C70.9566 27.0056 87.6868 13.6303 107.887 16.0678Z'
			/>
			<path
				stroke='transparent'
				d='M130.459 52.7578C127.868 53.5445 124.136 49.6093 124.722 26.9406C121.79 28.485 115.436 33.8105 106.591 33.6534C92.2954 33.6697 80.5194 42.311 73.5353 47.9466C69.7324 51.0151 64.8428 55.1619 59.3 61.1224C55.3406 65.3801 46.7427 78.7912 36.3896 82.2711C35.5386 82.6577 34.8792 81.9141 35.8534 81.5551C41.0278 79.6485 48.2119 70.0112 57.9447 56.7163C62.5754 50.3909 65.3249 47.6473 73.0532 41.9137C91.2371 29.401 98.635 30.3984 113.434 29.7672C115.179 29.853 119.58 26.239 123.481 23.0353L123.692 22.8622C124.546 22.1611 124.987 20.9932 125.012 19.8886C125.036 18.7937 125.147 17.173 125.43 14.8927C126.63 14.3177 129.255 14.0704 132.132 15.4334C131.575 17.1909 130.563 22.35 130.973 28.9265C131.204 32.6217 130.934 31.6382 133.859 43.126C134.758 46.6545 132.684 52.619 130.459 52.7578Z'
			/>
			<path
				d='M65.1448 118.81C71.4455 109.78 117.311 113.976 131.742 73.3551C142.558 75.7232 218.524 74.6177 203.744 159.565C218.023 112.277 198.636 74.1805 151.32 68.4513C157.011 64.8036 183.052 56.3796 192.629 56.9531C204.608 57.6705 241.713 56.0407 263.8 81.3609C272.218 91.0117 280.531 102.436 282.097 122.033C283.213 135.988 277.542 147.987 269.814 156.621C260.143 167.426 238.851 177.153 214.429 179.104C207.438 178.623 190.643 189.029 178.322 198.04C166.001 207.051 142.835 218.082 110.179 211.511C60.2195 201.46 55.1455 174.781 54.5228 142.726C54.3821 135.483 54.7772 127.163 65.1448 118.81Z'
				strokeWidth='2.71'
			/>
			<path
				d='M121.999 29.4721C129.136 30.9084 135.972 38.4065 131.766 42.2535C127.14 47.1376 137.592 55.9968 134.38 66.7004L134.337 66.8425C130.096 80.9761 124.214 100.579 75.7908 113.947C55.1786 119.637 57.9663 129.416 23.8357 129.134C44.3715 109.937 28.8024 97.1364 56.3638 65.3322C61.3231 61.3134 71.5175 49.6784 80.9116 43.2533C85.0689 40.4099 101.799 27.0346 121.999 29.4721Z'
				strokeWidth='2.71'
			/>
			<path
				d='M144.571 66.1621C141.981 66.9488 138.248 63.0136 138.835 40.3449C135.902 41.8893 129.549 47.2148 120.703 47.0577C106.408 47.074 94.6317 55.7153 87.6476 61.3509C83.8447 64.4194 78.9551 68.5662 73.4123 74.5267C69.4529 78.7844 60.855 92.1955 50.5019 95.6754C49.6509 96.062 48.9915 95.3184 49.9657 94.9593C55.1401 93.0528 62.3242 83.4155 72.057 70.1206C76.6877 63.7952 79.4372 61.0516 87.1655 55.318C105.349 42.8053 112.747 43.8027 127.546 43.1715C129.291 43.2573 133.692 39.6433 137.593 36.4396L137.804 36.2665C138.658 35.5654 139.099 34.3975 139.124 33.2929C139.148 32.198 139.259 30.5773 139.542 28.297C140.743 27.722 143.367 27.4747 146.245 28.8377C145.687 30.5952 144.675 35.7543 145.086 42.3308C145.316 46.026 145.046 45.0425 147.972 56.5303C148.87 60.0588 146.797 66.0233 144.571 66.1621Z'
				strokeWidth='2.71'
			/>
		</svg>
	);
};

export default FlatPeachImage;
