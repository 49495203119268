import React, { useEffect, useState } from "react";
import { useApp } from "hook/useApp";
import { whyIcons, whyTradeBerry } from "services/contactServices";
import Slider from "react-slick";
import SlideItem from "../SlideItem/SlideItem";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "../../style.module.css";

const MySlider = ({ slider }) => {
  const [whyTradeBerryInfo, setWhyTradeBerryInfo] = useState([]);
  const [whyTradeBerryIcon, setWhyTradeBerryIcon] = useState([]);
  const { language } = useApp();
  const fetchWhyTradeBerry = async () => {
    // get the Slider items array and save them in hooks
    try {
      const response = await whyTradeBerry(`?lang=${language}`);
      const tradeBerryData = response?.data || [];
      const iconPromises = tradeBerryData.map((el) => {
        const result = el?._links?.["wp:attachment"]?.[0]?.href;
        return whyIcons(result);
      });
      const iconResponses = await Promise.all(iconPromises);
      const icons = iconResponses.map((response) => response?.data?.[0]);
      setWhyTradeBerryIcon(icons);
      setWhyTradeBerryInfo(tradeBerryData);
    } catch (error) {
      
    }
  };

  useEffect(() => {
    fetchWhyTradeBerry();
  }, [language]);

  useEffect(() => {
    // window.innerwidth is not working properly !!!!!

    // Function to change height of all slide elements after render
    const changeSlideHeight = () => {
      // Get all slide elements rendered by Slider
      const slideElements =
        slider.current.innerSlider.list.querySelectorAll(".slick-slide");

      // Loop through slide elements and apply styling
      const slideBodiesArray = Array.from(slideElements).map((element) => {
        return element.children[0].children[0].children[1];
      });

      const maxSlideBodyHeight = Math.max(
        ...slideBodiesArray.map((slideBody) => slideBody?.clientHeight)
      );

      slideBodiesArray.forEach((slideBody) => {
        slideBody.style = `height: ${maxSlideBodyHeight + 10}px`;
      });
    };

    // Call the function after render
    if (
      slider.current &&
      slider.current.innerSlider &&
      slider.current.innerSlider.list &&
      window.innerWidth < 431
    ) {
      changeSlideHeight();
    }
  }, [whyTradeBerryInfo]); // Run this effect whenever whyTradeBerryInfo changes

  const settings = {
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.slicSliderContent}>
      <Slider {...settings} ref={(c) => (slider.current = c)}>
        {whyTradeBerryInfo.length &&
          whyTradeBerryInfo.map((item, index) => (
            <SlideItem
              key={item.id}
              itemData={item}
              index={index}
              whyTradeBerryIcon={whyTradeBerryIcon}
            />
          ))}
      </Slider>
    </div>
  );
};

export default MySlider;
